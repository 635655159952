.editor {

  &__toolbar {
    background: $zircon;
    border: 1px solid $hawkes-blue;
    display: flex;
    align-items: center;
    padding: 8px 5px;
  }

  &__content {
    min-height: 62px;
    border: 1px solid $hawkes-blue;
    border-top: 0;
  }

  &__buttons {
    border-right: 1px solid $hawkes-blue;
    padding-right: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    padding-top: 1px;
    padding-bottom: 1px;

    &:last-child {
      border-right: 0;
    }

    button {
      padding: 0 5px;
      border-radius: 0;
      border: 0;
      height: 13px;
      display: flex;
      background: transparent;

      &:hover,
      &:focus,
      &:active {

        svg {
          path {
            fill: $gray;
          }
        }
      }

      &:focus {
        outline: none;
      }
    }
  }
}



.sort {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -15px;
  line-height: 2;

  @include media-breakpoint-down(sm) {
    margin-bottom: 5px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-right: 15px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__label {
    color: $dusty-gray;
    font-size: 14px;
    margin-right: 10px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
}
.notifications {
  margin-bottom: 30px;

  @include media-breakpoint-down(md) {
    padding-right: 30px;
  }

  &--absolute {
    position: fixed;
    bottom: 0;
    right: 30px;
    left: 30px;
    max-width: 390px;
    max-height: calc(100vh - 252px);
    padding-right: 0;
    margin-left: auto;

    @include media-breakpoint-down(md) {
      right: 15px;
      left: 15px;
    }

    .notifications__title {
      font-size: 13px;

      span {

        &:first-child {
          max-width: calc(100% - 30px);
        }
      }
    }

    .notifications__item-close {
      right: 13px;
      top: 13px;
      border: 0;
    }

    .notifications__text {
      font-size: 11px;
    }
  }

  &__item {
    background: $white;
    box-shadow: 0 4px 14px rgba(155, 150, 150, 0.25);
    border-radius: 5px;
    padding: 17px 15px;
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    transition: 200ms ease-out;

    &:last-child {
      margin-bottom: 0;
    }

    &--new {
      border-left: 5px solid $dodger-blue;

      .notifications__title {
        color: $dodger-blue;
      }
    }

    &-close {
      position: absolute;
      top: 0;
      right: -28px;
      border: 1px solid $hawkes-blue;
      border-radius: 2px;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      cursor: pointer;

      .icon {
        width: 8px;
        height: 8px;
      }
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
    }

    span {

      &:first-child {
        max-width: calc(100% - 175px);

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }
      }
    }
  }

  &__text {
    font-size: 13px;
    margin-top: 10px;
  }

  &__time {
    color: $silver-chalice;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }

    .icon {
      margin-right: 5px;
    }
  }
}
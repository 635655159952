.profile {
  display: flex;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }

  &-columns {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &--main-column {
    width: 100%;
  }

  &__form {
    width: calc(100% - 250px);
    padding-right: 30px;
    border-right: 1px solid $concrete;

    @include media-breakpoint-down(lg) {
      width: calc(100% - 230px);
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding-right: 0;
      border-right: 0;
    }
  }

  &__progress {
    width: 250px;
    padding-left: 30px;

    hr {
      margin: 30px 0;
    }

    @include media-breakpoint-down(lg) {
      width: 230px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 30px;
      width: 100%;
      padding-left: 0;
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .btns {
    margin-top: 40px;
  }

  .form-group {

    .form-control {
      font-size: 13px;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      align-items: flex-start;
    }

    label {
      margin-right: 20px;
      font-size: 14px;

      &:before {
        display: none;
      }

      @include media-breakpoint-down(lg) {
        margin-bottom: 10px;
      }
    }
  }

  .profile__progress .btn--transparent {
    font-size: 13px;
    min-height: 36px;
    border-color: $dodger-blue;
    font-weight: 600;
    width: 100%;

    &:hover {

      .icon {

        path {
          fill: $white;
        }
      }
    }
  }
}

.upload-photo {
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  color: $scorpion;

  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }

  &__photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: url("../images/photo.png") no-repeat center bottom;
    margin: 0 auto 10px;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }
}

//----------------------Public Profile
.public-profile {
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    table {
      max-width: 100%;

      tr {

        td {
          width: auto;
          min-width: 130px;
        }
      }
    }
  }

  &__photo {
    width: 157px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      display: block;
      margin: 0 auto 20px;
    }
  }

  &__name {
    margin-bottom: 20px;
    display: block;
  }

  &__info {
    width: calc(100% - 157px);
    padding-left: rem(60);

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding-left: 0;
    }
  }
}
.add-item {
  display: flex;
  align-items: center;

  &__content {
    width: calc(100% - 30px);
  }

  &__icon {
    margin-left: auto;
    cursor: pointer;
  }
}
@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

// sass-lint:disable no-vendor-prefixes
@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, $white, 50%),
  $border-radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  &::-webkit-scrollbar-track-piece {
    background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-track-color: $background-color;
    scrollbar-face-color: $foreground-color;
  }
}


@mixin scrollbar($background-color: $white) {
  @include scrollbars(5px, $alto, $background-color, 6px);
}

@mixin image-2x($image) {

  @media
  only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and ( min--moz-device-pixel-ratio: 1.25),
  only screen and ( -o-min-device-pixel-ratio: 1.25/1),
  only screen and ( min-device-pixel-ratio: 1.25),
  only screen and ( min-resolution: 200dpi),
  only screen and ( min-resolution: 1.25dppx) {

    background-image: url($image);
  }
}

.breadcrumbs {
  font-size: 26px;
  color: $gull-gray;
  line-height: rem(33);
  font-weight: 600;
  padding: 30px 0 0;

  @include media-breakpoint-down(md) {
    font-size: 22px;
    padding: 20px 0 0;
  }

  & > a,
  &__link {
    color: $dodger-blue;

    &:after {
      content: '>';
      margin-left: 10px;
    }
  }
}
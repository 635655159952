* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus {
  outline: none;
}

html,
body{
  height: 100%;
}

body {

  &.blur {

    .page-container {
      -webkit-filter: blur(8.5px);
      -moz-filter: blur(8.5px);
      -o-filter: blur(8.5px);
      -ms-filter: blur(8.5px);
      filter: blur(8.5px);
    }

  }
}

html {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  scroll-behavior: smooth;

  @include media-breakpoint-between(md, xl) {
    font-size: 80%;
  }

  @include media-breakpoint-down(md) {
    font-size: 60%;
  }

}

body {
  font: 16px/1em $f-primary;
  color: $black;
  background-color: $white;

  &.overflow {
    overflow: hidden;
  }
}

.video-page {
  min-height: 100%;
}

.page-container {
  display: flex;
  min-height: 100%;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.inner-container {
  max-width: 592px;

  @include media-breakpoint-down(md) {
    margin-left: auto;
    margin-right: auto;
  }

  &--large {
    max-width: 622px;

    @include media-breakpoint-down(md) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.main-col {
  padding: rem(50) rem(100);
  width: calc(100% - 33.5em);

  @include media-breakpoint-down(lg) {

    padding: 40px 50px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    padding: 40px 20px;
  }
}


a {
  transition: 300ms ease-out;
  color: $dodger-blue;

  &.color-link {
    text-decoration: underline;
    color: $radical-red;

    &:hover {
      color: $radical-red;
    }
  }

  &:hover {
    text-decoration: none;
    color: $dodger-blue;
  }

  &.icon-link {
    display: inline-flex;
    align-items: center;

    .icon {
      width: 13px;
      height: 11px;
      margin-right: 9px;
    }

    span {
      line-height: 1;
      border-bottom: 1px solid $dodger-blue;
    }
  }

  &.bold-link {
    text-decoration: underline;
    font-weight: 700;
    font-size: 16px;
    color: $dodger-blue;
    display: inline-flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

h1 {
  font-size: rem(46);
  line-height: rem(58);
  font-weight: bold;
  margin-bottom: rem(20);
}

h2 {
  font-size: rem(36);
  line-height: rem(45);
  font-weight: bold;
  margin-bottom: rem(60);
}

h3 {
  font-size: 24px;
  line-height: 30px;
}

h4 {
  font-size: 20px;
  margin-bottom: 20px;
}

h5 {
  font-size: 18px;
  font-weight: 700;
  color: $black;
  margin-bottom: 30px;
}

h6 {
  font-size: 16px;
  font-weight: 700;
  color: $black;
  margin-bottom: 10px;
  line-height: 20px;
}

hr {
  border-color: $concrete;
  margin: 20px 0 rem(40) 0;
}

.icon-text {
  font-size: 13px;
}

.text-inform {
  display: flex;
  font-size: 16px;
  color: $black;
  line-height: 24px;

  p {
    max-width: 700px;
  }

  .icon {
    margin-right: 10px;
    min-width: 17px;
    margin-top: 5px;
  }
}

.color-text {
  color: $dodger-blue;
}

.container--small {
  max-width: 745px;
  margin-left: auto;
  margin-right: auto;
}

article {

  p {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  ul,
  table {
    margin-bottom: 20px;
  }

  li {
    display: flex;
    align-items: baseline;

    &:before {
      content: '';
      display: block;
      vertical-align: middle;
      background: url("../images/list-point.svg") no-repeat center;
      background-size: contain;
      width: 10px;
      height: 10px;
      margin-right: 7px;
    }
  }
}

.pr-45 {
  padding-right: 45px;
}

.pl-30 {
  padding-left: 30px;
}

.list-pages {
  background: $concrete;
  padding: 50px 30px;
  margin: 200px auto;
  min-width: 500px;

  h3 {
    margin-bottom: 30px;
  }

  a {
    display: block;
    padding: 10px;
  }
}

.info-labels {
  display: flex;
  align-items: center;
  margin-top: 10px;

  img + img {
    margin-left: 14px;
  }
}

.color-dark {
  color: $black !important;
}

.color-bright {
  color: $gull-gray !important;
}

.max-width-100 {
  max-width: 100% !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.pr-lg-30 {

  @include media-breakpoint-up(lg) {
    padding-right: 30px !important;
  }
}

b {
  font-weight: 600;
}

.border-title {
  border-bottom: 1px solid $zumthor;
  padding-bottom: 10px;
  margin-bottom: 15px;

  b {
    font-weight: 700;
  }

  .color-bright {
    font-size: 13px;
    margin-top: 10px;
    color: $gray !important;
  }
}

.color-gray {
  color: $gray;
}

.back-page {
  border-bottom: 1px solid $concrete;
  padding: 22px 30px;
  background: $white;
  position: relative;
  border-radius: 5px 5px 0 0;
  z-index: 1;

  & + .admin-page__main-col__content {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.top-title-block {
  padding: 27px 30px;
  box-shadow: 0 4px 18px rgba(36, 80, 123, 0.12);
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;

  &__icon {
    margin-right: 20px;
  }
}

.text-underline {
  text-decoration: underline;
}
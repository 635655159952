.billing-card {
  margin-bottom: 40px;
  max-width: 340px;

  @include media-breakpoint-down(sm) {
    max-width: 230px;
  }

  .icon--edit {
    margin-right: 4px;
  }

  .icon-text {
    color: $black;
    cursor: pointer;
  }

  &--bg {
    background: url("../images/card.png") no-repeat center;
    width: 340px;
    height: 189px;
    background-size: cover;
    border-radius: 8px;
    box-shadow: 0 4px 26px rgba(115, 147, 181, 0.3);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px rem(30);

    @include media-breakpoint-down(sm) {
      width: 230px;
      height: 150px;
    }
  }

  &--info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    letter-spacing: 0.05em;

    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
  }

  &__name {
    font-size: 14px;
    text-transform: uppercase;
  }

  &__number {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    letter-spacing: 0.05em;
    justify-content: space-between;

    span {
      font-size: 20px;
      line-height: 16px;
      height: 9px;

      &:last-child {
        font-size: 18px;
        line-height: 23px;
        height: auto;

        @include media-breakpoint-down(sm) {
          font-size: 15px;
        }
      }

      @include media-breakpoint-down(sm) {
        font-size: 15px;
      }
    }
  }

  &__logo {
    width: 75px;

    img {
      max-width: 100%;
    }
  }
}
.tags {

  .form-control, input[type='text'] {
    border-color: $hawkes-blue;
    font-size: 13px;
    padding: 12px 10px;

    @include placeholder {
      font-size: 13px;
      color: $gull-gray;
    }
  }

  #shownlist {
    margin-top: 10px;

    span {
      background: $hawkes-blue;
      border-radius: 20px;
      height: 26px;
      padding: 5px 10px;
      display: inline-flex;
      align-items: center;
      line-height: 16px;
      white-space: nowrap;
      font-size: 13px;
      color: $emperor;
      margin: 0 10px 10px 0;
    }

    .icon {
      position: inherit;
      margin-left: 10px;
    }
  }
}
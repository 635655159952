.request {

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    .breadcrumbs {
      padding-top: 0;

      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }
    }
  }
}

.request-list {

  &__item {
    display: flex;
    padding: 15px 0 15px 15px;
    background: $white;
    box-shadow: 0 4px 14px rgba(155, 150, 150, 0.25);
    border-radius: 5px;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 10px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    &-status {
      width: 200px;
      display: flex;
      padding-left: 15px;
      padding-right: 15px;
      border-left: 1px solid $hawkes-blue;

      @include media-breakpoint-down(sm) {
        display: flex;
        justify-content: center;
        border-left: 0;
        border-top: 1px solid $hawkes-blue;
        padding-top: 15px;
        width: 100%;
        margin-top: 15px;
      }
    }

    &-content {
      padding-right: 20px;
      min-width: calc(100% - 200px);
      width: 100%;

      &__top {
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
          display: flex;
          flex-direction: column;
        }
      }

      &__bottom {
        margin-top: 10px;
      }

      &__top,
      &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }

      .status {
        font-size: 13px;
        font-weight: 400;
        margin-left: 10px;

        @include media-breakpoint-down(sm) {
          margin-left: 0;
          margin-bottom: 5px;
        }

        &:before {
          width: 8px;
          height: 8px;
          margin-right: 5px;
        }
      }
    }
  }

  &__value {

    b {
      font-size: 16px;
      font-weight: 700;
      margin-left: 10px;
    }
  }

  &__info {
    font-weight: 700;
    font-size: 16px;

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 10px;
    }
  }

  &__name {
    font-weight: 700;
    color: $dodger-blue;
  }

  &__time {
    color: $gray;
  }

  .radio-group {
    display: flex;
    align-items: center;
    justify-content: center;

    &__label {
      min-width: 81px;
      margin-right: 10px;
    }

    &__item {
      display: block;
      margin-right: 0;

      &:last-child {
        margin-bottom: 0;

        .radio-group__label {
          margin-right: 0;
        }
      }
    }
  }
}
.left-menu {
  margin-top: rem(60);

  &__item {
    display: flex;
    align-items: center;
    padding: 7px 18px 7px 30px;
    color: $black;
    margin-bottom: 5px;

    &.active {
      background: $alice-blue;
      border-left: 2px solid $dodger-blue;
      color: $dodger-blue;
      font-weight: bold;
    }
  }

  &__icon {
    display: block;
    width: 28px;
    height: 28px;
    margin-right: 15px;

    img {
      max-width: 100%;
    }
  }

  &__count {
    color: $dodger-blue;
    font-weight: 600;
    font-size: 11px;
    border: 1px solid $dodger-blue;
    border-radius: 4px;
    min-width: 24px;
    text-align: center;
    line-height: 14px;
    margin-left: 9px;
  }
}

.support {
  padding: 30px;
  font-weight: bold;

  .icon {
    margin-right: 10px;
  }

  &__a {
    color: $scorpion;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__inner {
    border-top: 1px solid $concrete;
    padding-top: 20px;
  }
}
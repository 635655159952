.left-col {
  background: $alice-blue;
  max-width: em(536);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: rem(220) rem(105) rem(80);
  line-height: 22px;

  @include media-breakpoint-down(lg) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: 100%;
    padding: 20px 20px 50px;
    text-align: center;
  }

  &__logo {
    margin-bottom: rem(96);
    max-width: 332px;

    @include media-breakpoint-down(md) {
      max-width: 200px;
      margin: auto;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

  }

  &__welcome {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
    font-weight: bold;

    &--violet {
      color: $purple-heart;
    }

    &--blue {
      color: $dodger-blue;
    }
  }

  &__f {
    color: $scorpion;

    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }

  }
}
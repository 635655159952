.progress {
  background: none;
  height: 3px;
  border-bottom: 1px solid $concrete;
  margin-bottom: 30px;
}

.progress-bar {
  background-color: $dodger-blue;
}

.progress-info {
  margin-top: 40px;
  max-width: 285px;

  @include media-breakpoint-down(sm) {
    margin-top: 0;

    .progress {
      margin-bottom: 0;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;

    h3 {
      font-weight: 700;
    }

    .progress-info__text {
      color: $dodger-blue;
      font-size: 13px;
      font-weight: 700;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 24px;
    color: $dodger-blue;
    margin-bottom: 10px;
  }

  &__sub-text {
    font-size: 12px;
    line-height: 15px;
    color: transparentize($black, 0.65);
    margin-bottom: 15px;
  }

  &--large {
    margin-top: 0;

    .progress {
      margin-bottom: 7px;
      height: 10px;
      background: rgba(30, 144, 254, 0.08);
      border-radius: 30px;
    }

    .progress-info__sub-text {
      color: $black;
    }

    .progress-bar {
      background: linear-gradient(247.25deg, #2F77BA 0%, $dodger-blue 100%);
      border-radius: 30px;
    }
  }

  &--complete {
    display: flex;
    align-items: center;
    max-width: inherit;
    padding: 20px 30px;
    box-shadow: 0 13px 15px rgba(36,80,123,0.07);

    @include media-breakpoint-down(md) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &__main {
      width: calc(100% - 61px);
      max-width: 580px;
      padding-left: 20px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        padding-left: 0;
      }
    }

    &__icon {
      padding-top: 7px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .progress-bar {
      background: linear-gradient(242.7deg, $green 0%, rgba(3, 206, 12, 0.4) 100%);
      border-radius: 30px;
    }

    .progress-info__sub-text {
      color: $green;
      font-size: 11px;
      line-height: 14px;
      margin-bottom: 0;
    }

    .progress-info__title {
      margin-bottom: 13px;
    }

    .progress-info__row {
      display: flex;
      align-items: center;

      .progress {
        width: calc(100% - 45px);
      }

      .progress-info__text {
        width: 45px;
        text-align: right;
        color: $green;
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
}
$colors: ("yellow", $sunflower),
  ("blue", $dodger-blue),
  ("green", $green),
  ("d-green", $la-palma),
  ("red", $radical-red),
  ("bordo", $milano-red),
  ("d-red", $totem-pole),
  ("blue", $dodger-blue),
  ("d-blue", $tory-blue),
  ("orange", $christine);


.status {

  &--point {

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;
      display: inline-block;
    }
  }

  &-bg {
    border-radius: 20px;
    color: $white;
    min-width: 120px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding: 5px 15px;
    display: inline-flex;
    font-weight: 700;
  }

  &--check {
    font-size: 13px;
    font-weight: 400;
  }

  @each $color in $colors {

    &-bg--#{nth($color,1)} {
      background: nth($color, 2);
    }

    &--#{nth($color,1)} {
      color: nth($color, 2) !important;

      &:before {
        background: nth($color, 2);
      }

      &.underline {
        border-bottom: 1px solid nth($color, 2);
      }
    }
  }

}

.status-group {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn {
    white-space: nowrap;
    margin-top: -7px;

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }

  }
}
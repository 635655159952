.admin-page {

  @include media-breakpoint-down(md) {
    flex-direction: row;
  }

  &__left-col {
    width: 228px;
    background: $link-water;
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: auto;
    position: sticky;
    left: 0;
    top: 0;
    height: 100vh;

    @include media-breakpoint-down(md) {
      left: -228px;
      position: fixed;
      transition: 400ms ease-in-out;
      z-index: 3;
      box-shadow: 5px 0 10px 0 rgba(155, 150, 150, 0.15);
    }

    &--opened {
      left: 0;
    }
  }

  &__main-col {
    width: calc(100% - 228px);
    padding: 80px 104px 40px 30px;
    background: $alice-blue;
    position: relative;

    @include media-breakpoint-down(lg) {
      padding-right: 30px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }

    .list-content {
      max-width: 828px;

      .admin-page__main-col__content {
        padding-bottom: 0;
      }

      &--small {
        max-width: 786px;
      }

    }

    &__content {
      background: $white;
      margin-top: 30px;
      padding: 30px;
      box-shadow: 0 4px 14px rgba(155, 150, 150, 0.25);
      border-radius: 5px;
      min-height: 567px;
      position: relative;
      overflow: hidden;

      &.empty-content {
        min-height: 485px;
      }

      @include media-breakpoint-down(md) {
        padding: 30px 15px;
        margin-top: 20px;
      }
    }

    .form-group {

      .form-control {
        height: 48px;
      }

      label {
        font-size: 13px;
        color: $scorpion;
      }
    }

    .filter-dropdown {

      .form-group {

        .form-control {
          height: 40px;
        }

        label {
          font-size: 14px;
        }
      }

      .btns {

        .btn + .btn {
          margin-left: 0;
        }
      }
    }

    .modal {

      .form-group {

        .form-control {
          font-size: 16px;
          height: 60px;
        }

        label {
          font-size: 16px;
          color: $scorpion;
        }
      }

      .btns {
        margin-top: rem(30);
      }

      &--credit-request {

        .form-group {

          .form-control {

            &.dropdown-toggle {
              height: 48px;
            }
          }

        }
      }

      &--log-out {

        .btns {
          margin-top: 40px;
        }
      }

    }

    .btns {
      margin-top: 20px;

      @include media-breakpoint-up(md) {

        .btn + .btn {
          margin-left: 30px;
        }
      }
    }

    &.video-page {
      display: flex;
      flex-direction: column;
      padding-right: 30px;
      padding-bottom: 0;

      @include media-breakpoint-down(md) {
        padding: 0;
      }

      .breadcrumbs {

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  .form-group input[type=checkbox] + label:not(.radio-group__label),
  input[type=checkbox] + label:not(.radio-group__label) {
    font-size: 14px;
  }
}
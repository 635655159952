.filter-dropdown {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 260px;
  background: $white;
  border-radius: 5px 0 0 5px;
  padding: 34px 30px 30px;
  transition: 300ms ease-in-out;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &--open-left {
    left: -260px;

    @include media-breakpoint-down(sm) {
      left: -100%;
    }

    &.show {
      left: 0;
      box-shadow: 10px 0 18px rgba(36, 80, 123, 0.07);
    }
  }

  &--open-right {
    right: -260px;
    left: auto;
    border-radius: 0 5px 5px 0;

    &.show {
      right: 0;
      box-shadow: -10px 0px 18px rgba(36, 80, 123, 0.07);
    }

    @include media-breakpoint-down(sm) {
      left: -100%;
      right: auto;

      &.show {
        left: 0;
        box-shadow: 10px 0 18px rgba(36, 80, 123, 0.07);
      }
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    color: $black;
    border-bottom: 1px solid $zumthor;
    padding-bottom: 34px;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    min-width: auto;
    padding: 0;
    min-height: auto;
  }

  &__content {
    overflow: scroll;
    height: calc(100% - 70px);
    padding-top: 20px;
  }

  .form-group {
    border-bottom: 1px solid $zumthor;
    padding-bottom: 10px;
    margin-bottom: 20px;

    &--last {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .check-group {

    &:first-of-type {
      margin-top: 5px;
    }

    input[type="checkbox"] + label,
    input[type="radio"] + label {
      font-size: 13px;
      color: $scorpion;
      margin-bottom: 0;
      transition: 200ms ease-out;

      &:hover {
        color: $dodger-blue;
      }

      &::before {
        height: 23px;
        width: 23px;
        border: 1px solid $hawkes-blue;
        border-radius: 2px;
      }
    }

    input[type="checkbox"]:checked + label,
    input[type="radio"]:checked + label {
      color: $scorpion;
      font-weight: normal;

      &::before {
        background: $white url("../images/check-drop.svg") center no-repeat;
        border: 1px solid $dodger-blue;
      }
    }
  }

  .btns {
    flex-direction: column;
    margin-top: 0;

    .btn {
      width: 100%;
      margin-bottom: 15px;

      &-link {
        font-weight: normal;
        text-decoration: underline;
        font-size: 15px;
      }
    }
  }
}
.modal {

  .search {
    max-width: 100%;
  }

  &__logo {
    width: rem(128);
    margin: 0 auto rem(16);
    min-width: 128px;
  }

  hr {
    border-color: $hawkes-blue;
    margin: 20px 0;
  }

  .form-group + hr {
    margin-top: 10px;
  }

  .dropdown {

    .dropdown-item {
      font-size: 16px;
    }
  }

  &-dialog {
    max-width: 722px;
  }

  &-content {
    padding: 50px;

    @include media-breakpoint-down(sm) {
      padding: 30px 20px;
    }
  }

  &-header {
    border-bottom: 0;
    padding: 0;
    font-size: 26px;
    line-height: 38px;
    color: $black;
    margin-bottom: 30px;
    font-weight: 700;
  }

  &-header--small {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    border-bottom: 0;
    padding: 0;
    justify-content: center;
    color: $emperor;
    margin-bottom: 20px;
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &-close {
    width: rem(23);
    height: rem(23);
    background: url("../images/close-gray.svg") no-repeat center;
    background-size: contain;
    position: absolute;
    right: rem(24);
    top: rem(24);
    z-index: 1;
    cursor: pointer;
    min-width: 14.5px;
    min-height: 14.5px;
  }

  &-body {
    padding: 0;
  }

  &-footer {
    border-top: 0;
    padding: 0;
  }

  .form-group {

    label {
      font-size: 16px;
      color: $scorpion;
    }
  }

  .btn--cancel {
    color: $dodger-blue;

    &:hover {
      color: $white;
    }
  }

  .btns {
    margin-top: em(40);
    width: 100%;
    justify-content: flex-end;

    .btn {
      min-width: 164px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;

      .btn {
        width: 100%;
        margin-bottom: 20px;
      }

      .btn + .btn {
        margin-left: 0;
      }
    }
  }

  p {
    font-size: 18px;
    line-height: 23px;
  }

  &--confirm {

    &__info {
      max-width: 480px;
      margin: auto;

      @include media-breakpoint-down(sm) {

        br {
          display: none;
        }
      }
    }

    .modal-dialog {
      max-width: 625px;
    }
  }

  &--confirm-large {

    .modal-dialog {
      max-width: 670px;
      font-size: 18px;
    }

    b {
      font-weight: 700;
    }
  }

  &--large {

    .modal-dialog {
      max-width: 1060px;
    }

    .modal-header {
      font-size: 26px;
      font-weight: 700;
      color: $black;
      margin-bottom: 30px;
    }

    .modal-content {
      padding: 50px;
      font-size: 16px;

      @include media-breakpoint-down(sm) {
        padding: 20px;
      }
    }

    .dropdown,
    .form-group input[type=checkbox] + label {
      font-size: 16px !important;
    }

    .btns {
      margin-top: 40px;
    }

  }

  &--medium {

    .modal-dialog {
      max-width: 803px;
    }
  }

  &--small {

    .modal-dialog {
      max-width: 540px;
    }
  }

  &--product {

    .modal-dialog {
      max-width: 500px;
    }
  }

  &--credit-request {

    .modal-dialog {
      max-width: 625px;
    }

    .dropdown {
      font-size: 16px;
    }
  }

  &--log-out {

    .modal-dialog {
      max-width: 540px;
    }

    .modal-content {
      padding: em(50) em(60) em(50);
    }

    .btns {
      justify-content: center;
      margin-top: 40px;
    }
  }

  &--delete {

    .modal-dialog {
      max-width: 458px;
    }
  }

  &--manage-tabs {

    .modal-dialog {
      max-width: 470px;
    }

    .btns {
      margin-top: 40px;

      .btn {
        min-width: 155px;
      }

      .btn + .btn {
        margin-left: 30px
      }
    }
  }

  &--training {

    .modal-dialog {
      max-width: 640px;
    }
  }

}


#end_session_modal,
#session_over_modal {
  position: absolute !important;
  bottom: 0;
  right: 0;
  margin: auto;

  .modal-dialog {
    margin: auto;
    max-height: 100%;
  }
}

#motivate_modal {

  .modal-content {
    border-radius: rem(30);
  }
}

.smiles {
  display: flex;
  justify-content: space-between;
  max-width: rem(528);
  margin: rem(10) auto 0;

  @include media-breakpoint-down(md) {
    max-width: 100%;
    margin-top: 20px;
  }

  p {
    line-height: 16px;
  }

  &__item {
    font-weight: 700;
    font-size: rem(20);
    color: $black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    img {
      width: rem(24);
      height: rem(24);
      border-radius: 50%;
      display: block;
      margin-bottom: 5px;
      min-width: 18px;
      min-height: 18px;
    }
  }
}

.motivated-form {
  margin: 0 auto;
  font-size: 13px;
  line-height: rem(27);
  font-weight: 600;
  text-align: center;

  p {
    font-size: 13px;
    line-height: rem(27);
    font-weight: 600;
  }

  .form-group {

    label {
      font-size: 16px;
      color: $scorpion;
    }
  }

}

.image-group {

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
    cursor: pointer;
    font-weight: 700;
    font-size: rem(20);
    color: $black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: 200ms ease-out;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    &:hover {
      color: $dodger-blue;
    }
  }

  input[type="radio"]:checked + label {
    color: $dodger-blue;
  }
}
.btn {
  padding: 10px em(45);
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  border-radius: 0;
  box-shadow: none !important;
  min-height: 50px;
  min-width: 155px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 200ms linear;

  &[disabled] {
    cursor: not-allowed;
  }

  &--icon-right {
    padding-left: 20px;
    padding-right: 20px;

    .icon {
      margin-left: 10px;
      margin-right: 0 !important;
    }
  }

  .icon {
    margin-right: 10px;
    position: initial !important;
  }

  &--primary {
    transition: none;
    color: $white;
    background: linear-gradient(263.8deg, #2880D3 15.49%, #1D8CFB 85.43%);
    border: 0;

    &:hover {

      &:not([disabled], &--disabled) {
        background: $dodger-blue;
        color: $white;
      }
    }
  }

  &--round {
    border-radius: 20px;
    background: $dodger-blue;
    color: $white;
    height: 30px;
    font-size: 14px;
    min-width: 110px;
    padding: 0 10px;
    min-height: 30px;

    &:hover {
      color: $white;
      box-shadow: 0 5px 14px rgba(155, 150, 150, 0.4);
    }
  }

  &--simple {
    background: $dodger-blue;
    border-radius: 5px;
    color: $white;
    transition: 200ms ease-out;

    &:hover {
      &:not([disabled], &--disabled) {
        color: $white;
        box-shadow: 0 5px 14px rgba(155, 150, 150, 0.4) !important;
        background: #2880D3;
      }
    }

    &.btn--small {
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 700;
    }
  }

  &--transparent {
    border: 1px solid $hawkes-blue;
    background: none;
    padding-left: 15px;
    padding-right: 15px;
    color: $scorpion;

    &:hover {
      &:not([disabled], &--disabled) {
        color: $white;
        background-image: linear-gradient(263.8deg, #2880D3 15.49%, #1D8CFB 85.43%);;
      }
    }

    .icon {
      margin-right: 7px;
    }

    &.btn--small {
      border-color: $dodger-blue;
      color: $dodger-blue;

      &:hover {
        color: $white;
      }
    }

    &.btn--green {
      color: $green;
      border-color: $green;

      &:hover {
        background: $green;
        color: $white;

        path {
          fill: $white;
        }
      }
    }

    &.btn--red {
      color: $radical-red;
      border-color: $radical-red;
      background: none;

      &:hover {
        background: $radical-red;
        color: $white;
        border-color: $white;
      }
    }
  }


  &--small {
    font-size: 13px;
    min-height: 40px;
    font-weight: 600;
    min-width: auto;
    padding: 7px rem(30);
  }

  &--white {
    border: 1px solid $dodger-blue;
    border-radius: 5px;
    color: $dodger-blue;
    background: $white;
    transition: 200ms ease-out;

    &:hover {
      &:not([disabled], &--disabled) {
        color: $white;
        box-shadow: 0 5px 14px rgba(155, 150, 150, 0.4) !important;
        background: #2880D3;
        border: 1px solid #2880D3;
      }
    }
  }

  &--secondary {
    transition: none;
    color: $white;
    background: linear-gradient(263.44deg, #03CE0C 15.49%, #23D42B 85.43%);
    border: 0;

    &:hover {

      &:not([disabled], &--disabled) {
        background: #03CE0C;
        color: $white;
      }
    }
  }

  &--large {
    min-width: em(294);

    &:hover {
      background: none;
      color: #0056b3;
    }
  }

  &--medium {
    min-width: 200px;
  }

  &--cancel {
    color: $mine-shaft !important;
    border: 1px solid $hawkes-blue;

    &:hover {
      color: $white !important;

      &:not([disabled], &--disabled) {
        background: $radical-red;
        border-color: $radical-red;
      }
    }
  }

  &--red {
    background: $torch-red;
    border-color: $torch-red;
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

.btns {
  display: flex;
  align-items: center;

  .btn + .btn {
    margin-left: rem(42);
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .btn {
      width: 100%;
      margin-bottom: 20px;
    }

    .btn + .btn {
      margin-left: 0;
    }
  }

  &__text {
    font-size: 13px;
    margin-left: rem(50);
    display: inline-flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }

    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
  }
}

.btn-link {
  color: $dodger-blue;
  font-weight: bold;
  min-width: auto;
  padding: 0;
  min-height: auto;
  font-size: inherit;

  &--normal {
    font-weight: 400;
    font-size: 14px;
  }
}

#add_counselor {
  display: none;

  &.visible {
    display: block;
  }
}

.link-btns {

  .btn {
    margin-right: 15px;
  }

}
.view-page {
  font-size: 16px;
  line-height: 24px;

  &__h {
    border-bottom: 1px solid $link-water;
    padding: rem(20) rem(70);
    width: 100%;
    background: $white;

    @include media-breakpoint-down(sm) {
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
    }
  }

  &__top-block {
    padding: 55px 0 50px;
    text-align: center;
    font-size: 16px;
    border-bottom: 1px solid $link-water;
    background-color: $alabaster;
    background-image: url("../images/succes-bg-left@2x.png"), url("../images/succes-bg-right@2x.png");
    background-size: auto 300px, auto 345px;
    background-repeat: no-repeat;
    background-position: calc(50% - 500px) -85px, calc(50% + 540px) 30px;
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 24px rgba(36, 80, 123, 0.15) inset;

    @include media-breakpoint-down(md) {
      background-image: none;
    }
  }

  &__container {
    min-height: calc(100vh - 160px);
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__f {
    width: 100%;
    border-top: 1px solid $link-water;
    padding: 10px rem(70);
    font-size: 13px;
    position: relative;
    z-index: 1;
    background: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding-left: 20px;
      padding-right: 20px;
      flex-direction: column;
      align-items: center;
    }

    &__logo {
      max-width: 140px;
      display: flex;
      align-items: center;
    }
  }

  &__main {
    display: flex;
    height: 100%;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__left-column {
    width: 373px;
    padding: 30px 70px;
    background: $alabaster;

    @include media-breakpoint-down(md) {
      width: 280px;
      padding-left: 30px;
      padding-right: 30px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .btn {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  &__main-content {
    width: calc(100% - 373px);
    box-shadow: -10px 0px 24px rgba(36, 80, 123, 0.15);
    flex: 1;
    padding: 30px 50px 40px;

    @include media-breakpoint-up(xxl) {
      padding-right: 120px;
    }

    @include media-breakpoint-down(md) {
      width: calc(100% - 280px);
      padding-left: 30px;
      padding-right: 30px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  h4 {
    font-weight: 700;
    margin-bottom: 10px;
  }

  table {
    width: 100%;

    @include media-breakpoint-down(lg) {
      width: auto;
    }

    td {
      padding-bottom: 5px;
      padding-right: 20px;

      &:last-child {
        color: $calypso;
        font-weight: 700;
        padding-right: 0;
        text-align: right;

        @include media-breakpoint-down(lg) {
          text-align: left;
        }
      }
    }
  }

  ul {
    margin-right: 20px;
  }

  &__contacts {
    padding-bottom: 30px;
    border-bottom: 1px solid $link-water;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 30px;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-name {
      font-size: 31px;
      font-weight: 700;
      padding-top: 5px;
      line-height: 1;

      @include media-breakpoint-down(md) {
        font-size: 24px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }

      .color-text {
        font-size: 16px;
        font-weight: 400;
        margin-top: 15px;
      }
    }

    &-phone {
      font-size: 22px;
      line-height: 28px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      color: $dodger-blue;
      font-weight: 600;

      span {
        display: flex;
        align-items: center;
      }

      span + span {
        margin-top: 15px;

        @include media-breakpoint-down(lg) {
          margin-top: 0;
        }
      }

      @include media-breakpoint-down(lg) {
        font-size: 18px;
        margin-top: 15px;
        align-items: flex-start;

        .icon {
          width: 20px;
        }
      }

      .icon {
        margin-right: 12px;
      }
    }
  }

  &__user {

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 320px;
      margin: auto;
    }

    .btn {
      width: 100%;
    }

    &-img {
      border-radius: 30px 0;
      margin-bottom: 20px;
      max-width: 320px;
    }

    img {
      max-width: 100%;
      border-radius: 30px 0;
      display: block;
    }

    &-name {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
      text-align: center;
    }

    &-location {
      font-size: 13px;
      line-height: 19px;
      width: 100%;

      &__title {
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .icon {
          margin-right: 10px;
        }
      }

      &__addr {
        margin-bottom: 15px;
      }

      @include media-breakpoint-down(sm) {

        .btn {
          margin-bottom: 0;
        }
      }
    }

    &-btn {
      width: 100%;
    }
  }

  &__user-photos {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 20px;

    & > a {
      display: flex;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    img {
      width: 160px;
      border-radius: 7px;
      display: block;
      margin-right: 20px;
      margin-bottom: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--simple {

    .view-page {

      &__left-column {
        padding: 0;
        background: none;

        @include media-breakpoint-up(md) {
          width: 204px;
        }
      }

      &__main-content {
        box-shadow: none;
        padding: 0 0 0 30px;

        @include media-breakpoint-up(sm) {
          width: calc(100% - 204px);
        }

        @include media-breakpoint-down(sm) {
          padding: 0;
        }
      }

      &__user-location {
        margin-top: 10px;
      }

      &__contacts {

        @include media-breakpoint-down(lg) {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 20px;

          .view-page__contacts-name {
            margin-bottom: 10px;
          }
        }

        @include media-breakpoint-down(sm) {
          border-top: 1px solid $link-water;
          padding-top: 30px;
          width: 100%;
        }
      }
    }

    .btn {
      margin-bottom: 10px;
    }
  }
}

.success-block {
  width: 100%;
  font-size: 31px;
  line-height: 39px;
  color: $dodger-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-bottom: 25px;

  &__text {
    margin-left: 10px;
  }
}

.column-table {

  table {
    max-width: 500px;
  }
}


@include media-breakpoint-up(xxl) {
  .column {
    display: flex;
  }

  .column-article {
    width: calc(100% - 520px);
    min-width: 52%;
    padding-right: 30px;
  }


}

.dashboard {
  display: flex;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }

  b {
    font-weight: 700;
  }

  &__main {
    width: calc(100% - 292px);
    margin-right: 30px;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  &__aside {
    width: 292px;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

}

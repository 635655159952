.control-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 23px rgba(36, 80, 123, 0.13);
  border-radius: 5px;
  background: $white;
  padding: 20px 30px;
  margin-top: 30px;
  position: relative;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 15px;
  }

  &__link {
    color: $dodger-blue;
    font-weight: 700;
    font-size: 16px;

    .icon--prev {
      margin-right: 7px;
    }

    .icon--next {
      margin-left: 7px;
    }
  }

  &__controls {

    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    a + a {
      margin-left: 30px;
    }
  }

  &__info {
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    max-width: 220px;

    @include media-breakpoint-down(sm) {
      margin: 15px 0 20px;
    }

    &__text {
      width: calc(100% - 30px);
      padding-left: 10px;
    }
  }
}

.control-links + .admin-page__main-col__content {
  margin-top: -10px;
}
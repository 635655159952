.bs-stepper-content {
  padding: 0;

  h2 {
    margin-bottom: rem(60);

    @include media-breakpoint-up(xl){
      max-width: calc(100% - 200px);
    }

    @include media-breakpoint-down(md){
      margin-bottom: 30px;
    }
  }
}

.bs-stepper {

  .step {
    position: relative;

    &-trigger {
      margin-right: 49px;
      padding: 0;
      font-size: 14px;
      font-weight: bold;

      &:hover {
        background-color: transparent;
      }

      &:disabled {
        opacity: 1;
      }
    }

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      top: 12px;
      background: $hawkes-blue;
      left: auto;
      right: 0;
      width: calc(100% - 24px);
    }

    &:last-child {

      .step-trigger {
        margin-right: 0;
      }

      &:after {
        display: none;
      }
    }

    &.active {

      .bs-stepper-circle {
        border-color: $dodger-blue;
        color: $dodger-blue;
        background-color: transparent;
      }
    }

    &--complete {

      .bs-stepper-circle {
        border-color: $dodger-blue;
        color: transparent;
        opacity: 1;
        background: $dodger-blue url("../images/complete.svg") no-repeat center;
      }

      &:after {
        background: $dodger-blue;
      }
    }
  }

  &-header {
    width: 170px;
    margin: rem(60) auto rem(60) auto;

    @include media-breakpoint-up(xl){
      margin: rem(60) 0 -30px auto;
    }
  }
}

.bs-stepper-circle {
  background-color: transparent;
  border: 2px solid $hawkes-blue;
  color: $hawkes-blue;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 20px;
  padding: 0;
}
.content-video {
  max-width: 460px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;

  video {
    object-fit: fill;
  }

  &:hover {

    .vidControls {
      transform: translateY(0) !important;
      z-index: 2;
    }
  }

  &__desc {
    color: $white;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    font-size: 16px;
    font-weight: 700;
    z-index: 1;
    width: 100%;

    @include media-breakpoint-down(sm) {
      padding: 10px;
    }
  }
}

.rtopVideoPlayerWrapper {
  border-radius: 10px;

  .rtopVideoPlayer:not(.finished) .rtopVideoHolder.hasFAIcons:after {
    content: "";
    background: url("../images/play.svg") no-repeat center;
    background-size: contain !important;
    width: 40px;
    height: 40px;
    left: 0;
    right: 0;
    margin: auto;
    transition: opacity .3s ease-in;

    @include media-breakpoint-down(sm) {
      width: 30px;
      height: 30px;
    }
  }

  .rtopVideoPlayer.finished .rtopVideoHolder {


    &.hasFAIcons:after {
      transition: opacity .3s ease-in;
      font-size: rem(40);
    }
  }

  .rtopVideoPlayer {

    &.paused {

      .rtopVideoHolder {

        &.hasFAIcons:after {
          background: url("../images/play.svg") no-repeat center;
          animation: none;
        }

        &:before {
          animation: none;
        }
      }
    }

    .rtopVideoHolder {
      border-radius: 10px;

      &:before {
        border-radius: 10px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 121.54%);
      }

    }
  }


}

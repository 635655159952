$input-height: 60px;
$input-font-size: 16px;

.form {

  &--pass {
    max-width: 426px;
  }

  &--lesson {
    max-width: 765px;
    font-size: 13px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 700px;

    .form-control {
      font-size: 13px;
    }

    .add-items-wrapper {

      .add-item {
        width: calc(100% + 30px);
        margin-bottom: 20px;

        hr {
          margin: 0;
        }
      }
    }

    hr {
      margin: 20px 0;
    }

    .link-btns {
      margin-bottom: 20px;
    }
  }
}

.form-group {
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
  flex-direction: column;
  position: relative;

  label {
    margin-bottom: 10px;
  }

  .icon {
    position: absolute;
    right: 20px;
    top: 47px;
    cursor: pointer;
  }

  &__text-info {
    font-size: 12px;
    text-align: right;
    margin-top: 2px;
    margin-bottom: 20px;
  }

  .form-control:not(.form-control--w-text) {
    margin-bottom: 0;
  }

  .btn-link {
    margin-top: 20px;
  }

  &--icon-no-label {

    .form-control {
      padding-right: 50px;
    }

    .icon {
      top: 0;
      margin: auto;
      bottom: 0;
    }
  }

  .error {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transform: translateY(10px);
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    background: $mine-shaft;
    color: $white;
    font-size: 11px;
    padding: 8px 6px;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 0;
      right: 0;
      margin: auto;
      width: 14px;
      height: 7px;
      border-top: solid 7px $mine-shaft;
      border-left: solid 7px transparent;
      border-right: solid 7px transparent;
    }

    &.visible {
      opacity: 1;
      transform: none;
      display: inline;
    }
  }

  &--editable {

    label {
      color: $black !important;
    }

    .form-control {
      position: relative;
      border: 1px solid $concrete;
      display: flex;
      align-items: center;
      padding-right: 0;

      &--focus {
        border-color: $hawkes-blue;
      }
    }

    input {
      font-size: 13px;
      border: 0;
      width: calc(100% - 86px);

      &:disabled {
        color: $silver-chalice;
      }
    }

    .edit-icons {
      display: flex;
      width: 86px;

      .icon {
        position: inherit;
        top: 0;
        right: auto;
      }

      &__group {
        display: flex;
        height: 16px;
        visibility: hidden;

        &__item {
          width: 43px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid $hawkes-blue;
        }
      }

      &__pencil {
        width: 43px;
        border-left: 1px solid $concrete;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  &--select {

    .dropdown-item {
      padding: 8px 0;
      font-size: 16px;

      input[type=radio] + label,
      input[type=checkbox] + label {
        font-size: 16px !important;
      }
    }

    .dropdown-menu {
      padding-bottom: 0 !important;
    }

    .dropdown.show {

      .dropdown-toggle {
        border-bottom: 1px solid $hawkes-blue;
      }

      .dropdown-menu__inner {
        border-top: 0;
        max-height: 140px;
      }
    }

  }

  &--input-small {

    .form-control {
      height: 48px;
    }

    .icon {
      top: 41px;
    }
  }

}

.success {
  display: none;
  font-size: 13px;

  &.visible {
    display: inline;
  }
}


.label-required {
  position: relative;

  &:after {
    content: "*";
    position: absolute;
    top: 0;
    right: -8px;
    color: $radical-red;
  }
}

.form-label {
  width: 100%;
}

.form-control {
  height: $input-height;
  font-size: $input-font-size;
  border: 1px solid $hawkes-blue;
  width: 100%;
  color: $black;
  font-family: $f-primary;
  border-radius: 0;
  margin-bottom: 20px;
  padding: 0 20px;
  transition: 300ms ease-out;
  background-clip: border-box;
  -webkit-appearance: none;

  &--hidden {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  &--round {
    border-radius: 20px;
    height: 30px;
    font-size: 14px;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
    background-color: $hawkes-blue;
    border-color: $hawkes-blue;
    color: $black;
  }

  @include placeholder {
    font-size: $input-font-size;
    color: $black;
  }

  &.field {
    padding: 20px;
  }

  &--w-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 0;
    justify-content: space-between;
    margin-bottom: 10px;

    span {
      font-size: 13px;
      color: $silver-chalice;
    }

    input {
      border: 0;
      width: calc(100% - 50px);
      height: 100%;
      background: none;
      font-size: 13px;
      color: $scorpion;

      &:focus {
        background: none;
      }
    }
  }
}

textarea {

  &.form-control {
    min-height: 123px;
    padding: 15px 20px;

    @include placeholder {
      color: $silver-chalice;
    }
  }
}

select {
  padding-left: 10px;
  padding-right: 50px;
  appearance: none; /*Chrome, Safari и Opera*/
  -webkit-appearance: none; /*Chrome, Safari и Opera*/
  -moz-appearance: none; /*Firefox*/
  text-indent: 0.01px; /*Firefox*/
  text-overflow: ''; /*Firefox*/
  background: #fff url('../images/arrow-down.svg') no-repeat 96% center !important;
  background: #fff url('../images/arrow-down.svg') no-repeat calc(100% - 18px) center !important;
  background: #fff url('../images/arrow-down.svg') no-repeat -moz-calc(100% - 18px) center !important;
  background: #fff url('../images/arrow-down.svg') no-repeat -webkit-calc(100% - 18px) center !important;

  &.form-control {
    padding-right: 40px;
  }

  &:focus {
    outline: 0;
  }
}

select::-ms-expand {
  display: none;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: $hawkes-blue;
  background-image: none;

  &:focus {
    box-shadow: none;
    border-color: $hawkes-blue;
  }
}

.form-control.validate-control:valid:not([type=password]):not([type=search]):not(textarea),
.form-control.valid:not(textarea) {
  border-color: $hawkes-blue;
  padding-right: 20px;
  background-image: url("../images/valid.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: calc(100% - 18px) center;

  & + .icon--invalid {
    display: none;
  }

  &:focus {
    box-shadow: none;
    border-color: $hawkes-blue;
  }
}

.form-control.is-invalid,
input:required:focus:invalid,
.was-validated .form-control:invalid {
  border-color: $torch-red;
  color: $torch-red;
  padding-right: 20px;
  background-image: url("../images/invalid.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: calc(100% - 18px) center;

  & + .icon--invalid {
    display: block;
    top: 43px;
  }

  & + .icon--info {
    display: none;
  }

  &:focus {
    box-shadow: none;
  }
}

//---------------------Checkboxes---------------//

.dropdown-item:not(.checked-list__item),
.check-group {

  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  input[type="checkbox"] + label,
  input[type="radio"] + label,
  .form-group input[type="checkbox"] + label,
  .form-group input[type="radio"] + label {
    cursor: pointer;
    font-size: 16px;
    color: $black;
    margin-bottom: 0;
    transition: 200ms ease-out;

    &:hover {
      color: $dodger-blue;
    }

    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 22px;
      width: 22px;
      background: transparent;
      border: 1px solid $black;
      margin-right: 15px;
    }
  }

  input[type="checkbox"]:checked + label,
  input[type="radio"]:checked + label {
    color: $dodger-blue;
    font-weight: bold;

    &::before {
      height: 23px;
      width: 23px;
      background: $white url("../images/check.svg") center no-repeat;
      border: 1px solid $dodger-blue;
    }
  }

  input[type="checkbox"]:disabled + label,
  input[type="radio"]:disabled + label {
    color: $silver-chalice !important;

    &::before {
      background: none !important;
      border: 1px solid $hawkes-blue !important;
    }
  }

  &--circle {
    margin-bottom: 0;

    input[type="checkbox"] + label,
    input[type="radio"] + label,
    .form-group input[type="checkbox"] + label,
    .form-group input[type="radio"] + label {

      &::before {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: $gallery url("../images/check-cicle.svg") center no-repeat;
        border: 0;
        margin-right: 0;
        transition: background-color 100ms ease-out;
      }
    }

    input[type="checkbox"]:checked + label,
    input[type="radio"]:checked + label {

      &::before {
        height: 24px;
        width: 24px;
        border: 0;
        background: $dodger-blue url("../images/check-cicle.svg") center no-repeat;
      }
    }

    &-green {

      input[type="checkbox"]:checked + label,
      input[type="radio"]:checked + label {

        &::before {
          background: $green url("../images/check-cicle.svg") center no-repeat;
        }
      }

    }

    &-cross {

      input[type="checkbox"]:checked + label,
      input[type="radio"]:checked + label {

        &::before {
          background: $radical-red url("../images/uncheck.svg") center no-repeat;
        }
      }
    }
  }

  &--complete {
    align-items: center;
    margin-bottom: 0;

    input[type="checkbox"] + label,
    input[type="radio"] + label,
    .form-group input[type="checkbox"] + label,
    .form-group input[type="radio"] + label {
      padding-left: 36px;
      position: relative;
      font-size: 13px !important;

      &::before {
        height: 21px;
        width: 21px;
        background: $white;
        border: 1px solid $silver;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    input[type="checkbox"]:checked + label,
    input[type="radio"]:checked + label {

      &::before {
        height: 21px;
        width: 21px;
        background: $dodger-blue url("../images/check-complete.svg") 49% 50% no-repeat;
      }
    }

    input[type="checkbox"]:disabled + label,
    input[type="radio"]:disabled + label {
      color: $gray !important;
      cursor: inherit;

      &::before {
        height: 21px;
        width: 21px;
        border: 1px solid $silver !important;
        background: $silver url("../images/check-complete.svg") 49% 50% no-repeat !important;
      }
    }

  }

}

.check-group {
  margin-bottom: 10px;
}

//---------------------Radio Inputs---------------//

.radio-group {

  input[type="radio"],
  input[type="checkbox"] {
    display: none;

    & + label {
      font-size: 13px;

      &:before {
        display: none;
      }
    }
  }

  &__label {
    border-radius: 40px;
    font-size: 13px;
    cursor: pointer;
    color: $dodger-blue;
    margin-bottom: 0;
    transition: 200ms ease-out;
    border: 1px solid $dodger-blue;
    padding: 4px 13px;
    min-width: 120px;
    text-align: center;

    &--blue {
      border-color: $dodger-blue;
      color: $dodger-blue !important;
    }

    &--yellow {
      border-color: $sunflower;
      color: $sunflower !important;
    }

    &--green {
      border-color: $screamin-green;
      color: $emerand !important;
    }

    &--d-green {
      border-color: $green;
      color: $green !important;
    }

    &--red {
      border-color: $radical-red;
      color: $radical-red !important;
    }
  }

  input[type="radio"]:checked + label,
  input[type="checkbox"]:checked + label {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    color: $white !important;

    &:before {
      display: none;
    }

    &.radio-group__label--yellow {
      background: $sunflower;
      box-shadow: 0 4px 4px rgba($sunflower, 0.25);
    }

    &.radio-group__label--blue {
      background: $dodger-blue;
      box-shadow: 0 4px 4px rgba($dodger-blue, 0.25);
    }

    &.radio-group__label--green {
      background: $screamin-green;
      box-shadow: 0 4px 4px rgba($screamin-green, 0.25);
    }

    &.radio-group__label--d-green {
      background: $green;
      box-shadow: 0 4px 4px rgba($green, 0.25);
    }

    &.radio-group__label--red {
      background: $radical-red;
      box-shadow: 0 4px 4px rgba($radical-red, 0.25);
    }
  }

  &__item {
    display: inline-flex;
    margin-right: 20px;
  }

  &__title {
    font-size: 14px;
    color: $scorpion;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    label {
      font-size: 14px !important;
      margin-bottom: 0;
    }
  }
}

.radio-group-link {
  display: inline-flex;

  input[type="radio"] {
    display: none;
  }

  &__label {
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 0;
    transition: 200ms ease-out;
    margin-left: 15px;
    color: $dodger-blue;
    font-weight: 700;
  }

  input[type="radio"]:checked + label {
    color: $scorpion;
    cursor: inherit;
    font-weight: 400;
  }

  &--border {
    border: 1px solid $hawkes-blue;

    .radio-group-link__label {
      font-size: 16px;
      padding: 10px 15px;
      min-width: 86px;
      text-align: center;
      margin-left: 0;
      background: $white;
      font-weight: 600;
    }

    input[type="radio"]:checked + label {
      background: transparent;
      cursor: auto;
    }

    .radio-group-link__item {
      border: 0;
      border-right: 1px solid $hawkes-blue;

      &.active {

        label {
          color: $light-gray;
          cursor: inherit;
          font-weight: 400;
        }
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  &--small {
    border: 1px solid $zumthor;
    padding: 10px 0;

    .radio-group-link__label {
      font-size: 13px;
      color: $dodger-blue;
      min-width: 52px;
      padding: 0 15px;
    }

    input[type="radio"]:checked + label {
      color: $light-gray !important;
    }


  }
}


//---------------Input File
.file-group {

  &__text {
    font-size: 13px;
    color: $scorpion;
    display: block;
    margin-bottom: 10px;
  }

  &__files {
    display: flex;
    align-items: center;
  }

  &__images {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .icon {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  &__img {
    display: block;
    background-size: auto 70px;
    background-repeat: no-repeat;
    width: 112px;
    height: 70px;
    border-radius: 5px;
  }

  &__item {
    margin-right: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }

    label {
      display: flex;
      align-items: center;
    }

    .icon {
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    font-size: 13px;
    display: inline-block;
    white-space: nowrap;
  }

  .inputfile + label {
    cursor: pointer; /* "hand" cursor */
  }
}

.leads-edit-form {

  .form-control {
    font-size: 13px;
  }
}

.checked-list {

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $black;
    font-size: 16px;

    input[type="radio"],
    input[type="checkbox"] {
      display: none;

      & + label {
        border-radius: 40px;
        font-size: 13px;
        cursor: pointer;
        color: $dodger-blue;
        transition: 200ms ease-out;
        border: 1px solid $dodger-blue;
        padding: 4px 8px;
        min-width: 75px;
        text-align: center;
        font-weight: 400 !important;

        &:before {
          display: none;
        }
      }
    }

    input[type="radio"]:checked + label,
    input[type="checkbox"]:checked + label,
    input[type="radio"]:disabled + label,
    input[type="checkbox"]:checked + label {
      color: $white;
      background: $dodger-blue;
      font-weight: 400;
    }

    &--checked {
      color: $dodger-blue;
      font-weight: 700;
    }
  }
}
.table-filter {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  align-items: center;
  box-shadow: 0 6px 12px rgba($black, 0.07);
  border-radius: 5px 5px 0 0;
  position: sticky;

  &.hidden {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .btn-link {
    text-decoration: none;
    min-width: auto;
    padding: 0;
    min-height: auto;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }

  &__search {

    @include media-breakpoint-down(sm) {
      order: 1;
    }
  }

  &--hidden-dropdown-columns {

    .dropdown_columns_link {
      display: none;
    }
  }
}
.theme-list {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;

  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;

    img {
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.09);
    }

    &--inner {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-right: 60px;
    }

    &:last-child {

      .theme-list__item--inner,
      .theme-list__check {
        padding-right: 0;
      }
    }
  }

  &__label {
    font-size: 13px;
    color: $scorpion;
    margin-top: 30px;
    padding-bottom: 12px;
  }

  &__check {
    border-top: 1px solid $zumthor;
    padding-top: 17px;
    text-align: center;
    width: 100%;
    padding-right: 60px;
  }
}
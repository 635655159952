.empty-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;

  @include media-breakpoint-down(md) {
    padding: 0 20px;
  }

  h3 {
    margin: 20px 0 10px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    max-width: 285px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 100%;
    max-height: calc(100vh - 150px);
  }
}
.complete-list {

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $concrete;
    padding: 10px 0;
    font-size: 13px;
    line-height: 16px;

    &:last-child {
      border-bottom: 0;
    }
  }

  .check-group {
    margin-bottom: 0;
  }
}
.pagination {
  align-items: center;

  svg {

    path {
      fill: $dodger-blue;
    }
  }

  .page {

    &-item {
      color: $dodger-blue;
      padding: .5rem .75rem;
      background: none;
      border: 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.disabled {
        fill: $alto;
        cursor: inherit;

        svg {

          path {
            fill: $alto;
          }
        }

      }

      &.active {
          color: $silver-chalice;
          background: none;
          border-color: transparent;
          cursor: inherit;
      }
    }

    &-link {
      color: $dodger-blue;
      padding: .5rem .75rem;
      background: none;
      border: 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .pager {
    display: flex;
  }
}
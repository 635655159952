.academy {
  padding: 10px;

  .col-12 {
    display: flex;
  }

  &__item {
    background: $white;
    box-shadow: 0 4px 18px rgba(36, 80, 123, 0.16);
    border-radius: 5px;
    font-size: 13px;
    margin-bottom: 30px;
    overflow: hidden;
    width: 100%;
    position: relative;
    min-height: 229px;
    display: flex;
    flex-direction: column;

    &__h {
      box-shadow: 0 4px 18px rgba(36, 80, 123, 0.13);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 64px;
      padding: 15px;
    }

    &__main {
      padding: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &:hover  {

      .academy__hidden {
        transform: translateY(0);
      }
    }
  }

  &-logo {
    width: 122px;

    &__text {
      font-size: 11px;
      margin-left: auto;
      display: block;
      text-align: right;
      margin-top: -9px;
      margin-right: 5px;
    }

    img {
      max-width: 100%;
    }
  }

  &-title {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: $black;

    .icon {
      margin-right: 10px;
    }
  }

  .btn {
    width: 100%;
  }

  &-completed {
    color: $green;
    margin: 20px 0;

    b {
      font-weight: 700;
    }

    &__progress {
      height: 6px;
      background: rgba(30, 144, 254, 0.08);
      border-radius: 30px;

      .progress-bar {
        height: 100%;
        background: linear-gradient(207.01deg, #03CE0C 0%, rgba(3, 206, 12, 0.4) 100%);
        border-radius: 30px;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
    }
  }

  &__hidden {
    background: linear-gradient(180deg, rgba(252, 252, 252, 0) 0%, #FCFCFC 48.44%);
    border-radius: 5px;
    height: 120px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    transition: 400ms linear;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;
    flex: 1;

    @include media-breakpoint-down(md) {
      position: initial;
      transform: none;
      height: auto;
      padding-bottom: 0;
      margin-top: 20px;
    }

    .icon {
      width: 18px;
      height: 18px;
    }

    &__link {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &--edit {
        border: 1px solid $dodger-blue;
        margin-right: 30px;
      }

      &--delete {
        border: 1px solid $radical-red;
      }
    }


  }
}
.accordion {
  max-width: 620px;
  font-size: 13px;

  &-item {
    margin-bottom: 20px;
    box-shadow: 0 2px 10px transparentize($black, 0.88);
    border-radius: 5px;

    &__header {
      background: $alice-blue;
      padding: 16px 40px 16px 20px;
      position: relative;
      color: $scorpion;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 20px;
        bottom: 0;
        margin: auto;
        background: url("../images/dropdown-arrow.svg") no-repeat;
        width: 11px;
        height: 8px;
        transition: 200ms ease-out;
        transform: rotate(180deg);
      }

      &.collapsed {
        border-radius: 5px;
        background: $white;

        &:before {
          transform: rotate(0deg);
        }
      }
    }

    &__body {
      line-height: 19px;
      color: $black;
      padding: 0 20px 25px;
      background: $alice-blue;
      position: relative;


      &--inner {
        border-top: 1px solid $concrete;
        padding-top: 15px;
      }
    }

  }

}
$colors: ("yellow", $sunflower), ("blue", $dodger-blue), ("green", $green), ("red", $radical-red);

.circle-charts {
  display: flex;
  justify-content: space-between;
}

.chart-circle {
  position: relative;
  display: flex;
  justify-content: center;
  width: 170px;
  flex-direction: column;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  &__chart {
    height: 170px;
  }

  @each $color in $colors {
    &--#{nth($color,1)} {

      .rs-range-color {
        background-color: nth($color, 2);
      }

      .chart__title {
        color: nth($color, 2);
      }

      .chart-circle__chart {

        &:after {
          background: transparentize(nth($color, 2), 0.8);
        }

        &:before {
          border: 6.5px solid transparentize(nth($color, 2), 0.8);
        }
      }
    }
  }

  &__chart {

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 50%;
      z-index: 1;
    }

    &:after {
      width: 144px;
      height: 144px;
    }

    &:before {
      width: 170px;
      height: 170px;
    }
  }

  &__text {
    font-size: 16px;
    color: $dove-gray;
    position: relative;
    z-index: 2;
    font-family: $f-primary;
    text-align: center;
  }

  &__value {
    font-size: 36px;
    line-height: 45px;
    font-weight: 700;
    margin-top: 5px;
    display: block;
  }

  .rs-readonly {
    z-index: 0;
  }

  .rs-border {
    border: 0;
  }
}

.chart__title {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}

.bar-charts {
  max-width: 650px;
}

.chart-bar {
  width: 100%;
}


.chart-bar {
  display: flex;
  align-items: center;

  .chart__title {
    margin-top: 0;
    width: 120px;
    min-width: 120px;
    text-align: right;
    padding: 10px 10px 10px 0;
    position: relative;
    min-height: 30px;
    line-height: 30px;

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background: $zumthor;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__chart {
    width: 1%;
    height: 30px;
    border-radius: 0 7px 7px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__value {
    color: $white;
    font-size: 16px;
    font-weight: 700;
    margin-right: 10px;
  }

  @each $color in $colors {
    &--#{nth($color,1)} {

      .chart__title {
        color: nth($color, 2);
      }

      .chart-bar__chart {
        background: linear-gradient(-90deg, transparentize(nth($color, 2), 0.3) 0%, transparentize(nth($color, 2), 0.9) 100%);
      }
    }
  }
}

.chart {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;

  &__canvas {
    margin-top: 15px;
    width: calc(100% - 160px);

    &--graph {
      height: 220px;
      min-width: 600px;
    }
  }

  &__legend {
    padding-right: 30px;
    width: 160px;

    &__title {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .btn {
      width: 100%;
      font-weight: 700;
      border-radius: 2px;
      margin-top: 10px;
    }
  }
}

.legend-point {
  min-width: 135px;
  padding-left: 30px;

  &__item {
    display: flex;
    align-items: flex-start;
    font-weight: 700;
    margin-bottom: 12px;
    font-size: 13px;
  }

  &__point {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-top: 3px;
    margin-right: 7px;
  }
}

#chartjs-tooltip {
  transition: all .3s ease;
  pointer-events: none;
  transform: translate(-50%, -120%);
}

.tooltip-item {
  position: relative;

  &__text {
    display: block;
    text-align: center;
    padding: 1px 6px;
    border-radius: 6px;
    background: $white;
    min-width: 25px;
    border: 1px solid;
    position: relative;
    z-index: -1;
  }

  &__arrow {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    margin: auto;
    width: 6px;
    height: 6px;
    border: 1px solid;
    border-left: 0;
    border-top: 0;
    transform: rotate(45deg);
    z-index: -1;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-top-color: $white;
      transform: rotate(-45deg) translateY(-2px);
      z-index: 1;
    }
  }
}

.legend-group {

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 13px;

    &:not(.checked) {

      .legend-group__check {

        rect {
          stroke: $light-gray;
        }

        path {
          fill: transparent;
        }
      }
    }

    &.checked {

      .legend-group__label {
        font-weight: 700;
      }

    }
  }

  &__check {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 8px;

    path {
      transform: translate(4px, 5px);
    }
  }
}

.rs-animation .rs-transition {
  transition: all 1s linear 0s;
}

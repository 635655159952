.support-content {
  font-size: 13px;
  font-weight: normal;
  line-height: 19px;

  h4 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 10px;
  }

  ul {
    margin-bottom: 20px;

    li {
      margin-bottom: 10px;
    }
  }

  .support-contacts {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    &__item {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
        align-items: flex-start;
      }

      .icon {
        margin-right: 10px;
      }
    }
  }
}

.support--billing {
  display: flex;

  @include media-breakpoint-down(lg) {
    flex-direction: column-reverse;
  }

  &__table {
    width: 100%;
    max-width: 600px;
    padding-right: 30px;
  }
}

.support--credit-request {

  &__table {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $silver;

    .table {
      table-layout: fixed;

      th {
        color: $black;

        &:first-child {
          min-width: auto;
          padding-left: 0;
        }
      }

      td {
        color: $scorpion;

        &:first-child {
          min-width: auto;
          padding-left: 0;
        }
      }
    }
  }
}
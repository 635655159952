.video-page {

  .video-main {
    padding: 30px 0 30px;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  .btn--share-link {
    position: absolute;
    top: 30px;
    right: -15px;
    background: $dodger-blue;
    border-color: $dodger-blue;
    width: 50px;
    height: 50px;
    z-index: 1;

    @include media-breakpoint-down(md) {
      right: auto;
      left: 20px;
      top: 80px;
    }

    &.move {
      transform: translateY(60px);
    }

    &:before {
      width: 43px;
      height: 43px;
      border-color: $white;
      display: block !important;
    }

    svg {

      path {
        fill: $white;
      }
    }
  }
}

.btn--open-header {
  position: absolute;
  left: 20px;
  top: 20px;
  background: $dodger-blue;
  border: 0;
  width: 50px;
  height: 50px;

  &:before {
    display: none;
  }

  &.active {
    background: $white !important;
    top: 80px;

    svg {

      path {
        fill: $dodger-blue !important;
      }
    }
  }
}
.icon {
  position: relative;
  display: inline-flex;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  &--google {
    width: 20px;
    height: 20px;
    background-image: url("../images/google.svg");
  }

  &--info {
    width: 18px;
    height: 18px;
    background-image: url("../images/info.svg");
  }

  &--support {
    width: 20px;
    height: 20px;
    background-image: url("../images/support.svg");
  }

  &--edit {
    width: 10px;
    height: 10px;
    background-image: url("../images/edit.svg");
  }

  &--edit-blue {
    width: 14px;
    height: 14px;
    background-image: url("../images/edit-blue.svg");
  }

  &--edit-rect {
    width: 17px;
    height: 15px;
    background-image: url("../images/edit-rect.svg");
  }

  &--eye {
    width: 21px;
    height: 18px;
    background-image: url("../images/eye.svg");

    &.pass-show {
      background-image: url("../images/not-eye.svg");
    }
  }

  &--upload {
    width: 16px;
    height: 16px;
    background-image: url("../images/upload.svg");
  }

  &--point {
    height: 12px;
    width: 9px;
    background-image: url("../images/point.svg");
  }

  &--phone {
    width: 12px;
    height: 12px;
    background-image: url("../images/phone.svg");
  }

  &--phone-red {
    width: 12px;
    height: 12px;
    background-image: url("../images/phone.svg");
  }

  &--telephone {
    width: 21px;
    height: 21px;
    background-image: url("../images/telephone.svg");
  }

  &--mail {
    width: 12px;
    height: 12px;
    background-image: url("../images/mail.svg");
  }

  &--email {
    width: 20px;
    height: 14px;
    background-image: url("../images/email.svg");
  }

  &--email-blue {
    width: 23px;
    height: 15px;
    background-image: url("../images/email-blue.svg");
  }

  &--information {
    width: 17px;
    height: 17px;
    background-image: url("../images/information.svg");
  }

  &--invalid {
    width: 18px;
    height: 18px;
    background-image: url("../images/invalid.svg");
  }

  &--map {
    width: 12px;
    height: 16px;
    background-image: url("../images/map.svg");
  }

  &--pin {
    width: 20px;
    height: 20px;
    background-image: url("../images/pin.svg");
  }

  &--search {
    width: 16px;
    height: 16px;
    background-image: url("../images/search.svg");
  }

  &--filter {
    width: 22px;
    height: 16px;
    background-image: url("../images/filter.svg");
  }

  &--settings {
    width: 18px;
    height: 18px;
    background-image: url("../images/settings.svg");
  }

  &--close {
    width: 12px;
    height: 18px;
    background-image: url("../images/close.svg");
  }

  &--close-red {
    width: 12px;
    height: 16px;
    background-image: url("../images/close-red.svg");
  }

  &--close-blue {
    width: 11px;
    height: 11px;
    background-image: url("../images/close-blue.svg");
  }

  &--close-tag {
    width: 12px;
    height: 12px;
    background-image: url("../images/tag-close.svg");
  }

  &--admin {
    width: 12px;
    height: 12px;
    background-image: url("../images/admin.svg");
  }

  &--card {
    width: 15px;
    height: 13px;
    background-image: url("../images/card.svg");
  }

  &--valid {
    width: 17px;
    height: 17px;
    background-image: url("../images/valid.svg");
  }

  &--logged {
    width: 23px;
    height: 26px;
    background-image: url("../images/logged.svg");
  }

  &--view {
    width: 10px;
    height: 10px;
    background-image: url("../images/view.svg");
  }

  &--delete {
    width: 14px;
    height: 15px;
    background-image: url("../images/delete.svg");

    &--large {
      width: 16px;
      height: 16px;
    }
  }

  &--calendar {
    width: 16px;
    height: 16px;
    background-image: url("../images/i-calendar.svg");
  }

  &--prev {
    width: 8px;
    height: 11px;
    background-image: url("../images/prev.svg");
  }

  &--next {
    width: 8px;
    height: 11px;
    background-image: url("../images/next.svg");
  }

  &--pencil {
    width: 16px;
    height: 16px;
    background-image: url("../images/pencil-blue.svg");
  }

  &--add {
    width: 14px;
    height: 14px;
    background-image: url("../images/add.svg");
  }

  &--warning {
    width: 24px;
    height: 21px;
    background-image: url("../images/warning.svg");
  }

  &--time {
    width: 14px;
    height: 14px;
    background-image: url("../images/time-gray.svg");
  }

  &--training {
    width: 26px;
    height: 22px;
    background-image: url("../images/training-gray.svg");
  }

  &--check-green {
    width: 11px;
    height: 8px;
    background-image: url("../images/check-green.svg");
  }

  &--call {
    width: 37px;
    height: 37px;
    background-image: url("../images/call.svg");
  }

  &--phone-no {
    width: 22px;
    height: 22px;
    background-image: url("../images/phone-no.svg");
  }

  &--video {
    width: 22px;
    height: 22px;
    background-image: url("../images/video.svg");
  }

  &--video-no {
    width: 22px;
    height: 22px;
    background-image: url("../images/video-no.svg");
  }

  &--camera {
    width: 16px;
    height: 10px;
    background-image: url("../images/camera-blue.svg");
  }

}
.search {
  position: relative;
  width: 100%;
  max-width: 490px;

  &:not(.search--autocomplete) {

    @include media-breakpoint-between(md, lg) {
      max-width: calc(100% - 302px);
    }
  }

  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }

  form {
    width: 100%;
  }

  &__btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    min-width: auto;
    min-height: auto;
    width: 55px;
    height: 48px;
    padding: 0;
    z-index: 1;

    .icon {
      margin-right: 0;
    }
  }

  input {
    padding-right: 50px;
    margin-bottom: 0;
    font-size: 13px;
    height: 48px;

    &:focus{

      @include placeholder{
        color: $mine-shaft;
      }
    }

    @include placeholder{
      color: $silver-chalice;
      font-size: 13px;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    width: 16px;
    height: 16px;

    .icon {
      position: initial;
      cursor: initial;
    }
  }

  &--autocomplete {

    input {
      border: 0;
      width: 100%;
      font-size: 16px;
      padding-left: 20px;
      height: 60px;

      @include placeholder{
        font-size: 16px;
      }
    }
  }
}
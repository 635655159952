@import url('https://fonts.googleapis.com/css?family=Muli:400,600,700&display=swap');

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

@import "variables";
@import "mixins/bootstrap-breakpoints";
@import "mixins/mixins";

@import "reset";
@import "common";
@import "animation";
@import "layout/left-col";
@import "layout/header-admin";
@import "layout/left-col-admin";

@import "components/buttons";
@import "components/forms";
@import "components/icons";
@import "components/stepper";
@import "components/tooltip";
@import "components/dropdown";
@import "components/breadcrumbs";
@import "components/table";
@import "components/status";
@import "components/pagination";
@import "components/billing-card";
@import "components/modals";
@import "components/tabs";
@import "components/progress-bar";
@import "components/accordion";
@import "components/theme-list";
@import "components/session-list";
@import "components/search";
@import "components/table-filter";
@import "components/filter-dropdown";
@import "components/tags";
@import "components/scrollbar";
@import "components/datepicker";
@import "components/empty-content";
@import "components/sort";
@import "components/notes-list";
@import "components/box-shadow-item";
@import "components/charts";
@import "components/editor";
@import "components/video";
@import "components/complete-list";
@import "components/control-links";
@import "components/add-item";
@import "components/gallery";
@import "components/notifications";
@import "components/academy";
@import "components/video-main";

@import "pages/sign-in";
@import "pages/create-account";
@import "pages/admin";
@import "pages/profile";
@import "pages/support";
@import "pages/view-page";
@import "pages/request";
@import "pages/dashboard";
@import "pages/training";
@import "pages/video-sessions";

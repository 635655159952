.tab-container {

  &--inner {
    max-width: 738px;
  }
}

.tabs {

  .nav {

    &-tabs {
      border-bottom: 1px solid $concrete;
      margin-bottom: 20px;
      flex-wrap: nowrap;
    }

    &-link {

      border-radius: 0;
      padding: 0;
    }

    &-link:not(.radio-group-link__item) {
      font-weight: 400;
      font-size: 14px;
      color: $scorpion;
      position: relative;
      border: 0;
      padding: 0 35px 16px ;
      white-space: nowrap;

      @include media-breakpoint-down(sm) {
        padding: 0 20px 12px;
      }

      &:before {
        content: '';
        height: 0;
        width: 100%;
        background: $dodger-blue;
        bottom: 1px;
        left: 0;
        transition: 300ms ease-in-out;
        position: absolute;
      }

      &.active {
        color: $dodger-blue;
        border: 0;
        font-weight: 700;

        &:before {
          height: 2px;
        }
      }
    }
  }

  .tab-link {
    margin-left: auto;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;

    .btn-link {
      font-weight: 400;
    }

    .icon {
      width: 14px;
      height: 14px;
    }
  }

  .tab-pane--border {
    border-right: 1px solid $concrete;
    padding-right: 30px;

    @include media-breakpoint-down(lg) {
      padding-right: 0;
      border-right: 0;
    }
  }

  .tab-pane--full-list {
    margin-left: -30px;
    margin-right: -30px;

    @include media-breakpoint-down(md) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .table-filter {
    margin-left: -30px;
    margin-right: -30px;

    @include media-breakpoint-down(md) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

}
$browser-context: 16; // Default

// fonts
$f-primary: 'Muli', sans-serif;

// colors
$white: #fff;
$catskill-white: #E0E9F2;
$mercury: #E5E5E5;
$alto: #D2D2D2; //#D9D9D9
$alabaster: #FAFAFA;
$silver: #BEBEBE; //#C7C7C7
$silver-chalice: #AEADAD; //#ADADAD, #ABABAB
$black: #000;
$god-gray: #191919;
$mine-shaft: #343434; //#3C3C3C
$emperor: #505050;
$gray: #888;
$purple-heart: #7A34DA;
$dodger-blue: #1E90FE; // #1E8BF7,  #1D8CFB
$calypso: #326394;
$tory-blue: #0C5AA2;
$scorpion: #5B5B5B; // #5F5F5F
$dusty-gray: #989898;
$hawkes-blue: #DDEDFD;
$alice-blue: #F4FAFF;
$link-water: #DEEBF9; // #C9DDF1
$zumthor: #ECF6FF; // #EBF5FF
$zircon: #F8FBFF;
$gallery: #EDEDED; //#F0F0F0
$gull-gray: #A0AEBB; //#9FADBB
$dove-gray: #676767;
$light-gray: #99AFBB;
$green: #03CE0C;
$emerand: #3FDA45;
$screamin-green: #62EF67;
$la-palma: #148D19;
$radical-red: #FE1E54;
$torch-red: #FE1E4E;
$milano-red: #C10909;
$totem-pole: #AD0D0D;
$concrete: #F2F2F2;
$sunflower: #E1D928;
$christine: #EC780E;

// Bootstrap Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1900px
)


.h {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  box-shadow: 0 4px 7px rgba(155, 150, 150, 0.15);
  background: $white;
  padding: 20px 0 20px 30px;
  z-index: 4;
  transition: 200ms linear;

  @include media-breakpoint-down(md) {
    padding-left: 20px;
    height: 60px;

    &.h--video-mobile {
      transform:  translateY(-100%);

      &.show {
        transform: translateY(0);
      }
    }
  }

  &__logo {

    img {
      max-height: 36px;
    }
  }

  &__logged {
    display: flex;
    align-items: center;
    font-weight: 700;

    @include media-breakpoint-down(md) {
      display: none;
    }

    .icon {
      margin-right: 10px;
    }

    .btn {
      margin-left: 20px;
      font-weight: 700;
    }

    .color-text {
      margin-left: 8px;
    }
  }

  &__copy-link {
    font-size: 12px;
    width: 100%;
    max-width: 529px;
    margin-bottom: -18px;

    @include media-breakpoint-down(md) {
      width: auto;
      margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &__label {
      color: $scorpion;
      margin-bottom: 8px;
    }

    &__input {
      position: relative;

      input {
        padding-right: 120px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .btn {
        position: absolute;
        top: 0;
        right: 0;

        @include media-breakpoint-down(md) {
          position: initial;
          width: 100%;
          max-width: 145px;
        }
      }
    }
  }

  &__user {

    &-name {
      font-weight: bold;
      margin-right: 10px;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &-status {
      font-size: 11px;
      display: flex;
      font-weight: 400;
      margin-top: 3px;
      align-items: center;

      .icon {
        margin-right: 3px;
      }
    }

    &-photo {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      margin-right: 5px;

      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        display: block;
      }
    }
  }

  //--------------------------------MOBILE MENU
  &__mobile {
    z-index: 1;
    width: 26px;
    height: 16px;
    margin-right: 35px;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &__btn {
      position: relative;
      width: 100%;
      height: 100%;
      transform: rotate(0deg);
      transition: 0.3s ease-in-out;
      cursor: pointer;
      border: 0;
      padding: 0;
      background: transparent;
      box-shadow: none;
      display: block;

      &:focus {
        outline: 0;
      }

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        opacity: 1;
        left: 0;
        background: $black;
        transform: rotate(0deg);
        transition: 0.2s ease-in-out;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: -webkit-calc(50% - 1px);
          top: -moz-calc(50% - 1px);
          top: calc(50% - 1px);
          width: 50%;
        }

        &:nth-child(3) {
          bottom: 0;
        }
      }
    }

    &--opened {
      height: 20px;

      span {

        &:nth-child(1) {
          top: 50%;
          width: 0;
          right: 50%;
        }

        &:nth-child(2) {
          top: 10px;
          width: 100%;
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          top: 10px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
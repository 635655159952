.box-shadow-item {
  background: $white;
  box-shadow: 0 12px 28px rgba(36, 80, 123, 0.12);
  border-radius: 10px;
  margin-top: 30px;

  &--small {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 20px;
  }

  &__top {
    box-shadow: 0 9px 13px rgba(36, 80, 123, 0.07);
    border-radius: 10px;
    min-height: 65px;
    display: flex;
    align-items: center;
    padding: 20px em(30);
  }

  &__title,
  h4 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .color-text {
      font-size: 13px;
      margin-top: 5px;
      margin-left: 30px;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(md) {
        margin-left: 0;
      }

      .icon {
        margin-left: 10px;
      }
    }

    &:not(.border-title) {
      margin-bottom: 0;
    }
  }

  &__content {
    padding: 20px em(30);

    .table {

      td {
        vertical-align: middle;
        padding: 10px 15px;

        &:first-child {
          padding-left: 0;
        }
      }

      tr {

        &:first-child {

          td {
            padding-top: 0;
          }
        }

        &:last-child {

          td {
            padding-bottom: 0;
          }
        }
      }

      b {
        font-size: 16px;
      }

    }
  }

}
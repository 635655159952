.scroll-content-vertical {
  max-height: 250px;
}

.notes-list {
  margin-top: 20px;

  &__item {
    background: $alice-blue;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 19px;
    padding: 20px;
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-end;
    }

    &:last-child {
      margin-bottom: 10px;
    }
  }

  &__text {
    width: calc(100% - 130px);

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &__bottom {
    color: $gray;
    margin-top: 10px;
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    width: 130px;

    &__link {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.tooltip {
  font-size: 11px;

  &.show {
    opacity: 1;
  }
  //
  //.arrow {
  //  left: 50% !important;
  //}
}

.tooltip-inner {
  padding: 5px 6px;
  border-radius: 3px;
  background: $mine-shaft;
  max-width: 151px;
}

.bs-tooltip-auto[x-placement^=top] .arrow:before,
.bs-tooltip-top .arrow:before {
  top: 0;
  border-width: 8px 5px 0;
  border-top-color: $mine-shaft;
}
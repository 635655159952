.create-account {

  .form-group {

    label {
      color: $scorpion;
    }
  }

  .btns {
    justify-content: flex-end;
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    .btn + .btn {
      margin-left: 30px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;

      .btn {
        width: 100%;
        margin-bottom: 20px;
      }

      .btn + .btn {
        margin-left: 0;
      }
    }

  }
}
.session-list {
  max-width: 409px;

  &__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $zumthor;
    padding-bottom: 10px;
    align-items: center;

    &-left {
      display: flex;
      align-items: center;
    }

    &-points {
      font-size: 25px;
      line-height: 1;
      letter-spacing: 2px;
      color: $black;
      padding-bottom: 10px;
    }
  }

  &__title {
    font-size: 14px;
  }

  &__sub-title {
    font-size: 12px;
    color: $dusty-gray;
  }

  .icon {
    margin-right: 10px;
  }
}
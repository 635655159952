.scroll-content {

  .table--comfortable {

    td {
      white-space: nowrap;
    }
  }
}

.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-bottom: 70px;
}

.mCSB_scrollTools_vertical {
  transform: translateX(5px);
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  transform: scale(0.3);
  bottom: 30px;

  @include media-breakpoint-down(lg) {
    transform: scale(0.35);
  }

  @include media-breakpoint-down(md) {
    transform: scale(1);
    right: 15px;
    left: 15px;
  }
}

.nav-tabs--scroll {
  z-index: 1;

  .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    right: 0;
    left: 0;
    margin-bottom: 10px;
  }

  .mCSB_horizontal.mCSB_inside > .mCSB_container {
    margin-bottom: 0;
  }
}

.mCSB_outside {
  margin-bottom: 10px;
}

.mCSB_outside+.mCSB_scrollTools {
  right: -23px;
  margin-top: 15px;
}

.mCSB_scrollTools.mCSB_scrollTools_vertical {

  .mCSB_dragger {

    .mCSB_dragger_bar {
      width: 3px;
      border-radius: 1.5px;
    }
  }

  .mCSB_draggerRail {
    width: 1px;
  }
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 10px;
  margin: 7px 0;

  @include media-breakpoint-down(md) {
    height: 3px;
  }
}

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: $hawkes-blue;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 13px;

  @include media-breakpoint-down(md) {
    height: 5px;
  }
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: $dodger-blue !important;
  opacity: 1;
}

.mCSB_scrollTools {
  opacity: 1;
}

.mCSB_inside > .mCSB_container {
  margin-right: 20px;

  @include media-breakpoint-down(md) {
    margin-right: 10px;
  }
}
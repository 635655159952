.video-main {
  padding: 110px 30px 30px;
  width: 100%;
  display: flex;
  transition: 400ms ease-out;
  position: relative;
  max-width: 100%;
  height: 100%;

  @include media-breakpoint-down(md) {
    min-width: 100%;
    padding: 0;
  }
}

.video-container,
.image-container {
  background: linear-gradient(108.71deg, rgba(132, 245, 211, 0.6) 0%, rgba(122, 163, 243, 0.6) 100%);
  border-radius: 20px;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-container {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &--remove-bg {
    background: none;
  }

  @include media-breakpoint-up(md) {
    margin: 0 auto;
    min-height: 330px;
    min-width: 585px;
  }

  @include media-breakpoint-down(md) {
    border-radius: 0;
    max-width: 100% !important;
    max-height: 100% !important;
    background: $black;
  }
}

.image-container {

  @include media-breakpoint-down(md) {
    flex-direction: column;
    padding: 45px 20px 70px;

    .user-window {
      position: relative;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 0;
    }
  }
}

.video-call {
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  position: relative;

  &__black-place {
    height: 100%;
    width: 100%;
    background: $black;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: 200ms ease-in-out;
    z-index: 1;
    border-radius: 20px;

    @include media-breakpoint-down(md) {
      border-radius: 0;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }

  video {
    max-width: 100%;
    width: 100%;
    margin: auto;
    display: block;
    border-radius: 20px;

    @include media-breakpoint-down(md) {
      border-radius: 0;
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }

  .call-time {
    position: absolute;
    bottom: 20px;
    left: 30px;

    @include media-breakpoint-down(sm) {
      left: 20px;
      bottom: 30px;
      margin-bottom: 0;
    }
  }
}

.image-call {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__photo {
    width: 196px;
    height: 196px;
    position: relative;

    @include media-breakpoint-down(md) {
      width: 150px;
      height: 150px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      top: 0;
      margin: auto;
      transform: translateX(-50%);
      border-radius: 50%;
      background: rgba(21, 94, 165, 0.07);
    }

    &:before {
      width: calc(100% + 40px);
      height: calc(100% + 40px);
      animation: wave 2s ease-in-out alternate-reverse infinite;
      animation-delay: 0.4s;
    }

    &:after {
      width: calc(100% + 60px);
      height: calc(100% + 60px);
      animation: wave 2s ease-in-out alternate-reverse infinite;
      animation-delay: 0.6s;
    }

    &-inner {
      position: relative;
      display: block;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        top: 0;
        margin: auto;
        transform: translateX(-50%);
        border-radius: 50%;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        animation: wave 2s ease-in-out alternate-reverse infinite;
        animation-delay: 0.2s;
        background: rgba(21, 94, 165, 0.07);
      }
    }

    img {
      max-width: 100%;
      border-radius: 50%;
    }
  }

  .call-time {
    margin-top: rem(50);

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }
}

.call-time {
  margin-top: rem(50);
  font-size: 12px;
  font-weight: 700;
  background: rgba($black, 0.7);
  border-radius: 30px;
  height: 24px;
  min-width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  padding: 5px 10px;

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
}

.call-btns {
  display: flex;
  align-items: center;
  bottom: -20px;
  z-index: 2;
  position: absolute;

  &:focus {
    box-shadow: none;
  }

  @include media-breakpoint-down(md) {
    bottom: 30px;
  }

  .btn + .btn {
    margin-left: 30px;

    @include media-breakpoint-down(md) {
      margin-left: 20px;
    }
  }

  &__button {
    width: 60px;
    height: 60px;
    min-width: auto;
    padding: 0;
    border-radius: 50%;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before {
      content: '';
      width: 52px;
      height: 52px;
      border-radius: 50%;
      border: 2px solid $radical-red;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    .icon {
      margin-right: 0;
    }

    &.active:not(.call-btns__button--call) {
      background: $dodger-blue;

      &:before {
        display: none;
      }

      .icon--phone-no {
        background-image: url("../images/phone-session.svg");
      }

      .icon--video-no {
        background-image: url("../images/video-call.svg");
      }
    }

    &--call {
      width: 72px;
      height: 72px;
      background: $radical-red;
      box-shadow: 0 4px 4px rgba($radical-red, 0.15);

      &:before {
        display: none;
      }
    }
  }
}

.user-window {
  position: absolute;
  right: 65px;
  bottom: 20px;
  display: flex;
  align-items: flex-start;
  width: 180px;
  height: 120px;
  border-radius: 15px;
  transition: 400ms ease-out;
  z-index: 1;

  @include media-breakpoint-down(md) {
    width: 100px;
    height: 125px;
    right: 20px;
    left: auto;
    bottom: auto;
    top: 20px;
    border-radius: 6px;
  }

  img {
    display: flex;
    max-width: 100%;
  }

  video {
    width: 100%;
    height: auto;
    border-radius: 15px;
    display: block;
    overflow: hidden;
    z-index: 1;
    margin: 0;

    @include media-breakpoint-down(md) {
      border-radius: 4px;
    }
  }
}
.lg-backdrop {
  background: $god-gray;
}

.lg-toolbar {
  background: none;
}

.lg-outer {

  .lg-img-wrap,
  .lg {
    padding: 0 40px;
  }

  .lg-thumb {
    padding: 20px 0 30px;
  }

  .lg-thumb-outer {
    background: none;
  }

  .lg-thumb-item {
    border: 2px solid transparent;
    position: relative;
    border-radius: 0;

    &.active {
      border-color: $dodger-blue;
    }

    &:not(.active) {

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(196, 196, 196, 0.5);
      }
    }
  }
}

.lg-hide-items {

  .lg-next,
  .lg-prev,
  .lg-close,
  .lg-toolbar {
    opacity: 1;
    transform: none;
  }
}

.lg-actions {

  .lg-next,
  .lg-prev {
    background-color: transparent;

    &:after {
      display: none;
    }

    &:before {
      content: '';
      width: 12px;
      height: 42px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .lg-next {
    right: 0;

    &:before {
      background-image: url("../images/next-gallery.svg");
    }
  }

  .lg-prev {
    left: 0;

    &:before {
      background-image: url("../images/prev-gallery.svg");
    }
  }

}

.lg-toolbar {

  .lg-close {
    position: absolute;
    width: 26px;
    padding: 0;
    right: 10px;
    top: 30px;

    &:after {
      content: '';
      width: 26px;
      height: 26px;
      position: absolute;
      left: 0;
      top: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../images/close-gallery.svg");
    }
  }
}
.sign-in {

  &__top-btns {
    margin-bottom: em(120);
    color: $scorpion;

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    .btn {

      @include media-breakpoint-up(md) {
        margin-left: rem(42);
      }

      @include media-breakpoint-down(sm) {
        margin-top: 30px;
      }
    }
  }

  h3 {
    margin-bottom: rem(60);
  }

  .btns {
    justify-content: space-between;
    margin-top: 40px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;

      .btn {
        width: 100%;
        margin-bottom: 20px;
      }

      .btn + .btn {
        margin-left: 0;
      }
    }
  }
}
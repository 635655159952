.dropdown {
  border: 1px solid $hawkes-blue;

  .form-control,
  .dropdown-toggle {
    text-align: left;
    align-items: center;
    margin-bottom: 0;
    transition: none;
    display: flex;
    cursor: pointer;

    &:focus {
      background: none;
    }

    &:after {
      border: 0;
      background: url("../images/dropdown-arrow.svg") no-repeat;
      width: 11px;
      height: 8px;
      transition: 200ms ease-out;
    }
  }

  .dropdown-toggle {
    border: 0;
  }

  .form-control {
    display: flex;
    justify-content: space-between;
  }

  &-item {
    padding: 9px 0;
    cursor: pointer;
    line-height: 1;

    &:hover {
      color: $dodger-blue;
      background-color: transparent;
    }

    &.checked {
      font-weight: 700;
    }

    &--row {
      display: table-row;

      &:last-child {

        .dropdown-item--cell {
          padding-bottom: 0;
        }
      }
    }

    &--cell {
      display: table-cell;
      padding: 7px 10px;
      align-items: center;
      font-size: 16px;
      line-height: 20px;

      b {
        font-weight: 700;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &.show {

    .form-control {

      &:after {
        transform: rotate(180deg);
      }
    }
  }

  &-top-links {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    .btn-link {
      margin-right: 20px;
      font-size: 13px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--user {
    border: 0;

    .dropdown-toggle {
      cursor: pointer;
      padding-right: 30px;
      padding-left: 30px;

      @include media-breakpoint-down(md) {
        padding-left: 20px;
        padding-right: 20px;
      }

      &:after {
        background: url('../images/check.svg') no-repeat;
        width: 8px;
        height: 6px;
        background-size: contain;
        transition: 200ms ease-out;
      }
    }

    &.show {

      .dropdown-toggle {

        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  &--status {
    min-width: 155px;
    border: 0;

    .dropdown-menu {
      margin-top: -14px;
      min-width: 100%;
      padding: 20px 20px 10px;
      border-radius: 0 0 5px 5px;
      background: $white;
      box-shadow: 0 4px 12px rgba(36, 80, 123, 0.27);
      border: 0;
      z-index: 1;
    }

    .dropdown-item {

      input[type="radio"] + label {

        &:before {
          display: none;
        }
      }
    }

    .dropdown-toggle {
      border-radius: 40px;
      border: 1px solid rgba(30, 144, 254, 0.4);
      font-size: 13px;
      justify-content: space-between;
      background: $white;
      padding: 5px 12px;
      color: $dodger-blue;
      z-index: 2;

      &:after {
        background: url("../images/dropdown-arrow-blue.svg") no-repeat center !important;
      }
    }
  }

  &--select {

    input[type="radio"] + label {

      &:before {
        display: none;
      }
    }

    .dropdown-toggle {
      display: flex;
      position: relative;

      .status {
        color: $dodger-blue !important;

        &:before {
          display: none;
        }
      }

      &:after {
        background: url("../images/dropdown-arrow.svg") no-repeat center;
      }
    }

    .dropdown-menu:not(.dropdown-menu--status) {
      background: $white;
      min-width: 204px;
      border-radius: 0;
      border: 0;
    }

    .dropdown-item {

      input[type=radio] + label {
        font-size: 13px;
      }
    }

    &.show {

      .dropdown-toggle {

        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  &--select-primary {
    border: 0;

    .dropdown-toggle {
      color: $dodger-blue;
      font-size: 14px;

      &:after {
        background: url("../images/dropdown-arrow-blue.svg") no-repeat center;
      }
    }

    .dropdown-menu {
      padding: 20px 30px !important;
      box-shadow: 0 4px 12px rgba(36, 80, 123, 0.27);
    }
  }
}

.dropdown-menu__inner {
  max-height: 220px;
  padding-top: 10px;
  border-top: 1px solid $hawkes-blue;
}

.dropdown-menu:not(.datepicker):not(.user-menu):not(.dropdown-menu--status) {
  min-width: calc(100% + 2px);
  margin-left: -1px;
  margin-top: 0;
  border-radius: 0;
  border: 1px solid $hawkes-blue;
  border-top: 0;
  padding: 0 20px 20px;

  &.dropdown-menu--inline {
    border-top: 1px solid $hawkes-blue;
  }

  &__inner {
    padding-top: 10px;
    border-top: 1px solid $hawkes-blue;
  }

  &[x-placement="top-start"] {
    margin-top: 1px;
    border-top: 1px solid $hawkes-blue;

    .dropdown-menu__inner {
      border-top: 0;
    }
  }

  &.show[x-placement="bottom-start"] {
    border-top: 0;

    &__inner {
      padding-top: 10px;
    }
  }
}

.dropdown-menu--inline {
  position: inherit;
  border-top: 1px solid $hawkes-blue;

  .dropdown-menu__inner {
    height: 215px;
    max-height: none;
    border-top: 0;
  }

  .dropdown-item:not(.checked-list__item) {
    font-size: 16px;
    font-weight: 700;
    color: $dodger-blue;

    input[type=checkbox] + label:not(.checked-list__label),
    input[type=radio] + label:not(.radio-group__label) {
      font-size: 16px !important;
      font-weight: 400;
    }

    input[type=checkbox]:checked + label,
    input[type=radio]:checked + label {
      font-weight: 700;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.dropdown-toggle--inline:after {
  transform: rotate(180deg);
}

.user-menu {
  width: 100%;
  border: 0;
  background: $white;
  box-shadow: 0 4px 14px rgba(155, 150, 150, 0.25), 0 7px 7px -4px rgba(155, 150, 150, 0.25) inset;
  border-radius: 0 0 0 5px;
  margin-top: 19px;
  padding: 20px 30px 30px;
  min-width: 242px;

  @include media-breakpoint-down(md) {
    margin-top: 9px;
  }

  &__ul {

    li {
      border-bottom: 1px solid $zumthor;
      font-size: 13px;
      color: $scorpion;

      a {
        color: $scorpion;
        padding: 10px 0;
        display: block;

        &:hover {
          color: $dodger-blue;
        }
      }

      &:last-child {
        border-bottom: 0;

        a {
          padding-bottom: 0;
        }
      }
    }
  }

  & > a {
    font-size: 14px;
    color: $black;
    display: block;
    font-weight: 600;

    &:hover {
      color: $dodger-blue;
    }
  }

  ul + a,
  ul + .btn {
    margin-top: 30px;
  }

  .btn {
    color: $dodger-blue;
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
    }

    &:hover {
      color: $white;
    }
  }
}

.dropdown-menu-selected {

  &.scroll-content-vertical {
    max-height: 260px;
  }

  &__section {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid $hawkes-blue;
    width: 100%;
    margin-bottom: 15px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    .dropdown-menu-selected__list {
      width: calc(100% - 180px);

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  &__label {
    width: 180px;
    font-size: 20px;
    font-weight: 700;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 20px;

      &:after {
        content: ':';
      }
    }
  }

  &__sub-label {
    font-weight: 400;
    font-size: 16px;
    color: $scorpion;
    margin-bottom: 10px;
  }

  &__list {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;

    & > li {
      padding: 15px 0;
      border-bottom: 1px solid $hawkes-blue;
      font-size: 16px;
      font-weight: 700;
      transition: 200ms ease-out;

      &:hover {
        cursor: pointer;

        .dropdown-menu-selected__link {
          visibility: visible;
          opacity: 1;
        }

        .dropdown-menu-selected__list__title {
          color: $dodger-blue;
        }
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    &__title {
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }

    &__zips {
      font-size: 13px;
      font-weight: 400;
      margin-top: 10px;
    }
  }


  &__link {
    font-size: 13px;
    color: $dodger-blue;
    display: flex;
    align-items: center;
    font-weight: 600;
    transition: 200ms ease-out;

    @include media-breakpoint-up(md) {
      opacity: 0;
      visibility: hidden;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 10px;
    }

    .icon {
      margin-right: 7px;
    }
  }

  &--page {
    margin-top: 20px;

    .dropdown-menu-selected__item {
      margin-bottom: 20px;
    }

    .dropdown-menu-selected__label {
      font-weight: 400;
      font-size: 16px;
      color: $scorpion;
    }

    .dropdown-menu-selected__list {
      width: 100%;
      font-size: 16px;
      margin-top: 10px;
      font-weight: 700;
    }

    .btn {
      margin-top: 20px;
      width: 100%;
    }
  }
}
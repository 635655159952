.training {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  &-main {
    width: calc(100% - 318px);
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 5px;

    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  &-aside {
    width: 318px;
    padding-left: 30px;

    @include media-breakpoint-down(lg) {
      width: 100%;
      padding-left: 0;
    }
  }

  &__top {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;

      .status {
        margin-bottom: 10px;
      }
    }

    &--border {
      border-bottom: 1px solid $concrete;
      margin-bottom: 15px;

      h6 {
        line-height: 24px;
      }
    }

    &__title {
      padding-right: rem(40);
      width: calc(100% - 120px);

      .color-bright {
        font-size: 11px;
        margin-top: 10px;
        margin-bottom: 10px;
        color: $gray !important;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        padding-right: 0;
      }
    }
  }

  article {
    font-size: 13px;
    line-height: 19px;

    p {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &-list {

    &__item {
      display: flex;
      align-items: center;
      font-size: 13px;
      border-bottom: 1px solid $zumthor;
      padding: 21px 0 21px 30px;
      border-left: 2px solid transparent;
      cursor: pointer;
      transition: 300ms ease-out;
      position: relative;

      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
        padding: 20px 15px;

        .training-list__icon {
          display: none;
        }
      }

      &:hover {
        border-left: 2px solid $dodger-blue;
        box-shadow: 0 4px 23px rgba(36, 80, 123, 0.13);

        &:before {
          opacity: 1;
        }

        .training-list__icon path {
          fill: $dodger-blue;
        }

        .training-list__title {

          b {
            color: $dodger-blue;
          }
        }
      }

      &--passed {
        background: transparentize($green, 0.97);
      }

      &:last-child {
        border-bottom: 0;
      }


      &--drag {

        &:before {
          content: '';
          position: absolute;
          left: 10px;
          top: 0;
          bottom: 0;
          margin: auto;
          background: url("../images/points.svg") no-repeat center;
          background-size: contain;
          width: 10px;
          height: 19px;
          opacity: 0;
          transition: 300ms ease-out;

          @include media-breakpoint-down(md) {
            opacity: 1;
          }
        }

        &:hover {

          &:before,
          .training-list__hidden-block {
            opacity: 1;
          }
        }

        .training-list__content {
          //width: calc(100% - 110px);

          @include media-breakpoint-down(sm) {
            width: 100%;
            padding-left: 15px;
          }
        }

        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }
      }
    }

    &__content {
      width: calc(100% - 130px);
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &__text {
        width: calc(100% - 40px);
        padding-left: 12px;

        @include media-breakpoint-down(sm) {
          padding-left: 0;
          width: 100%;
        }
      }
    }

    &__title {
      display: flex;
      justify-content: space-between;
      color: $scorpion;
      font-size: 16px;
      margin-bottom: 10px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      .color-gray {
        font-size: 11px;
      }

      b {
        font-weight: 700;
        padding-right: 20px;

        @include media-breakpoint-down(sm) {
          padding-right: 0;
          margin-bottom: 3px;
        }
      }
    }

    &__row {
      margin-top: 10px;
    }

    &__status {
      text-align: center;
      width: 150px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        text-align: right;
      }

      .icon {
        margin-right: 10px;
      }
    }

    &__hidden-block {
      text-align: center;
      width: 150px;
      transition: 300ms linear;

      @include media-breakpoint-up(lg) {
        opacity: 0;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        text-align: left;
        margin-top: 15px;
        margin-left: 30px;
      }

      a + a {
        margin-left: 20px;
      }
    }
  }

  &-title {
    font-weight: 700;
    display: flex;
    align-items: center;

    .status {
      margin-left: 20px;
      font-size: 13px;
      font-weight: 400;

      .icon {
        margin-right: 7px;
      }
    }
  }

  .btns {
    justify-content: flex-end;
    margin-top: 40px;

    .btn {
      min-width: 224px;
    }
  }
}
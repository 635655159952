.daterangepicker {
  background: $white;
  box-shadow: 0 4px 18px rgba(36, 80, 123, 0.07);
  border: 0;
  margin-top: 1px;

  &:after,
  &:before {
    display: none !important;
  }

  .next,
  .prev {
    visibility: hidden;
  }

  select.monthselect,
  select.yearselect {
    color: $black;
    font-size: 17px;
    border: 0;
    background: #fff url('../images/select.svg') no-repeat 96% center !important;
    background: #fff url('../images/select.svg') no-repeat calc(100% - 35px) center !important;
    background: #fff url('../images/select.svg') no-repeat -moz-calc(100% - 35px) center !important;
    background: #fff url('../images/select.svg') no-repeat -webkit-calc(100% - 35px) center !important;

  }

  .drp-calendar {
    max-width: none;

    &.left {
      padding: 0;
    }
  }

  thead {
    border-bottom: 1px solid rgba(112, 119, 160, 0.2);
  }

  table tr td {

    &.off {
      color: $alto;
    }
  }

  .calendar-table {

    td,
    th {
      width: 48px;
      height: 48px;
      font-size: 12px;
      font-family: $f-primary;
      color: $black;
      vertical-align: middle;
      border: 0;
      min-width: 48px;

      &.month {
        height: 36px;
      }
    }

    th {
      color: $dodger-blue;
      font-size: 13px;
      font-weight: normal;
    }


    td {
      position: relative;
      z-index: 0;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        transition: 200ms ease-out;
        z-index: -1;
      }

      &:hover, &.active.active {
        background: none;
        color: $black;

        &:before {
          background: $hawkes-blue;
          box-shadow: 0 2px 5px rgba(18, 49, 79, 0.17);
        }
      }
    }

  }
}
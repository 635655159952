.table {
  @include scrollbar;

  th,
  td,
  thead th {
    font-size: 13px;
    border-top: 0;
    border-bottom: 1px solid $zumthor;
    font-weight: normal;

    &:first-child {
      padding-left: 20px;

      @include media-breakpoint-up(xl) {
        min-width: 100px;
      }
    }

    &:last-child {
      padding-right: 20px;
    }

    .th--sortable {
      padding-right: 10px;
      white-space: nowrap;
      background: url("../images/arrow-asc.svg") no-repeat 100% 4.5px,
      url("../images/arrow-desc.svg") no-repeat 100% 9px;
      cursor: pointer;

      &.asc {
        background: url("../images/arrow-asc.svg") no-repeat 100% center;
      }

      &.desc {
        background: url("../images/arrow-desc.svg") no-repeat 100% center;
      }
    }
  }

  tr {
    transition: 200ms ease-out;

    &:last-child {

      td {
        border-bottom: 0;
      }
    }
  }

  th {
    color: $scorpion;
    padding: 16px 10px;
    position: relative;

    &.color-dark {
      color: $black;
    }

    &.text-large {
      font-size: 18px;
    }
  }

  td {
    color: $black;
    padding: 13px 7px;
  }

  b {
    font-weight: 700;
  }

  &__user {
    display: flex;
    align-items: center;

    &__photo {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 20px;

      img {
        display: block;
        max-width: 100%;
        border-radius: 50%;
      }
    }
  }

  &--comfortable:not(.table--content):not(.table--info) {

    th {
      padding-top: 19px;
      padding-bottom: 19px;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        height: 17px;
        background: $hawkes-blue;
        margin: auto;
      }
    }
  }

  &--comfortable {

    td {
      padding: 22px 10px;
    }
  }

  &-hover {

    tbody {

      tr:hover {
        background-color: $zumthor;
        box-shadow: 0 4px 4px rgba(132, 130, 130, 0.25), 2px 0 0 0 $dodger-blue inset;
        cursor: pointer;
      }
    }
  }

  &-views {
    margin-left: auto;
    font-size: 13px;
    text-align: right;

    @include media-breakpoint-down(sm) {
      text-align: left;
      margin-top: 20px;
    }

    &__label {
      color: $silver-chalice;
    }
  }

  .status {
    white-space: nowrap;
  }

  &--info {
    max-width: 605px;

    @include media-breakpoint-up(xl) {
      margin-bottom: em(55);
    }

    th {

      &:first-child {
        padding-left: 0;
      }
    }

    td {
      font-size: 13px;
      border-bottom: 1px solid $zumthor;
      line-height: 18px;

      &:first-child {
        color: $scorpion;
        padding-left: 0;
      }

      &.cell-right {
        text-align: right;
        padding-right: 30px;
      }

      ul {

        li + li {
          margin-top: 8px;
        }
      }
    }

    tr:first-child {

      td {
        padding-top: 0;
      }
    }

    &-dark {

      th {
        color: $black;
      }

      td {
        min-width: 80px;

        &:first-child {
          color: $scorpion;
          width: 300px;
          min-width: auto;
        }
      }
    }
  }

  &--content,
  &--large {
    max-width: 647px;

    th,
    td {
      min-width: 100px;

      &:first-child {
        padding-left: 0;
        min-width: 100px;
      }
    }
  }

  &--large {
    max-width: 834px;
    table-layout: fixed;

    th {
      color: $black;
    }

    td {

      &:first-child {
        color: $scorpion;
      }
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 20px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;

      .pagination {
        margin-bottom: 20px;
      }
    }

  }

  &--border-0 {

    th,
    td {
      border: 0 !important;

      &:last-child {
        padding-right: 0 !important;
      };
    }
  }

  &--nowrap {

    th,
    td {
      white-space: nowrap;
    }
  }
}